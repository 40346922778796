import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { ErrorCodes, getCustomerCountry, getCustomerDocument, getCustomerIdentification } from "@vfit/consumer/data-access";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
var setDateFormat = function(date) {
    var day = date.substring(0, 2);
    var month = date.substring(3, 5);
    var year = date.substring(6);
    return year.concat("-", month, "-", day);
};
var getErrorStatusText = function(status, errorCode) {
    if (status == "409") {
        return ErrorCodes.CUSTOMER_ALREADY_PRESENT;
    }
    if (status == "400") {
        return errorCode || "";
    }
    return errorCode || "";
};
var getPatchPayload = function(oData) {
    var ref;
    var contactMedium = ((oData === null || oData === void 0 ? void 0 : oData.owningIndividual) || {}).contactMedium;
    var identificationInfo = getCustomerIdentification(oData.owningIndividual);
    var selectedCountry = getCustomerCountry(oData);
    var selectedDocument = getCustomerDocument(oData);
    var validatedAddressResponse = getFromLocalStorageByKey("validatedAddress");
    var address = validatedAddressResponse === null || validatedAddressResponse === void 0 ? void 0 : (ref = validatedAddressResponse.validAddressList) === null || ref === void 0 ? void 0 : ref[0];
    var replaceAddress = function() {
        return [
            {
                type: "formattedAddress1",
                operation: "remove",
                index: 2
            },
            {
                type: "formattedAddress3",
                operation: "remove",
                index: 2
            },
            {
                updatedValue: (address === null || address === void 0 ? void 0 : address.city) || "",
                type: "city",
                operation: "replace",
                index: 2
            },
            {
                updatedValue: (address === null || address === void 0 ? void 0 : address.postalCode) || "",
                type: "postalCode",
                operation: "replace",
                index: 2
            },
            {
                updatedValue: (address === null || address === void 0 ? void 0 : address.streetNumber) || "",
                type: "streetNumber",
                operation: "replace",
                index: 2
            },
            {
                updatedValue: (address === null || address === void 0 ? void 0 : address.externalId) || "",
                type: "externalId",
                operation: "replace",
                index: 2
            },
            {
                updatedValue: (address === null || address === void 0 ? void 0 : address.stateOrProvince) || "",
                type: "stateOrProvince",
                operation: "replace",
                index: 2
            },
            {
                updatedValue: (address === null || address === void 0 ? void 0 : address.street) || "",
                type: "street",
                operation: "replace",
                index: 2
            },
            {
                updatedValue: (address === null || address === void 0 ? void 0 : address.istatCode) || "",
                type: "istatCode",
                operation: "replace",
                index: 2
            },
            {
                updatedValue: (address === null || address === void 0 ? void 0 : address.homeZone) || "",
                type: "homeZone",
                operation: "replace",
                index: 2
            },
            {
                updatedValue: (address === null || address === void 0 ? void 0 : address.latitude) || "",
                type: "latitude",
                operation: "replace",
                index: 2
            },
            {
                updatedValue: (address === null || address === void 0 ? void 0 : address.longitude) || "",
                type: "longitude",
                operation: "replace",
                index: 2
            }, 
        ];
    };
    var replaceContact = function() {
        var ref, ref1, ref2, ref3;
        return [
            {
                updatedValue: selectedDocument === null || selectedDocument === void 0 ? void 0 : (ref = selectedDocument[0]) === null || ref === void 0 ? void 0 : ref.id,
                type: "identificationType",
                operation: "replace",
                index: 0
            },
            {
                updatedValue: identificationInfo === null || identificationInfo === void 0 ? void 0 : identificationInfo.identificationNumber,
                type: "identificationNumber",
                operation: "replace",
                index: 0
            },
            {
                updatedValue: setDateFormat((identificationInfo === null || identificationInfo === void 0 ? void 0 : identificationInfo.expirationDate) || ""),
                type: "expirationDate",
                operation: "replace",
                index: 0
            },
            {
                updatedValue: (selectedCountry === null || selectedCountry === void 0 ? void 0 : (ref1 = selectedCountry[0]) === null || ref1 === void 0 ? void 0 : ref1.id) || "",
                type: "nationality",
                operation: "replace",
                index: 0
            },
            {
                updatedValue: (contactMedium === null || contactMedium === void 0 ? void 0 : (ref2 = contactMedium[0]) === null || ref2 === void 0 ? void 0 : ref2.emailAddress) || "",
                type: "email",
                operation: "replace",
                index: 0
            },
            {
                updatedValue: (contactMedium === null || contactMedium === void 0 ? void 0 : (ref3 = contactMedium[1]) === null || ref3 === void 0 ? void 0 : ref3.phoneNumber) || "",
                type: "number",
                operation: "replace",
                index: 1
            }, 
        ];
    };
    if (!address) return replaceContact();
    return _to_consumable_array(replaceContact()).concat(_to_consumable_array(replaceAddress()));
};
/**
 * ONLY IN FIRST PERIOD BS SIMULATION 24/11
 * this method return billingAccountData without new payments if is not in BS
 * @param billingAccountData
 * @param cmsGlobalConfig
 */ var getBSNewMopMobile = function(paymentData, cmsGlobalConfig) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
    var customerData = getFromLocalStorageByKey("customerData");
    var findCaller = getFromLocalStorageByKey("findCaller");
    var customerDataCF = (customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.fiscalCode) || (findCaller === null || findCaller === void 0 ? void 0 : (ref2 = findCaller.individualRef) === null || ref2 === void 0 ? void 0 : ref2.fiscalCode);
    var isCustomerXBS = customerDataCF === null || customerDataCF === void 0 ? void 0 : customerDataCF.includes("XBS");
    var defaultPayments = [
        "creditCard",
        "bankAccount",
        "wallet"
    ];
    var isGlobalBsNewMopMobile = cmsGlobalConfig === null || cmsGlobalConfig === void 0 ? void 0 : (ref3 = cmsGlobalConfig.globalconfig) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.newMop) === null || ref4 === void 0 ? void 0 : ref4.enableNewMopMobile;
    if (isGlobalBsNewMopMobile && isCustomerXBS) return paymentData;
    var availablePaymentMethods = paymentData === null || paymentData === void 0 ? void 0 : (ref5 = paymentData.orderPayment) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.orderOnBillPayment) === null || ref6 === void 0 ? void 0 : ref6.availablePaymentMethods;
    // HERE TO REDUCE THE AVAILABLE METHODS TO THE OLD ONES
    var oldAvailablePaymentMethods = availablePaymentMethods === null || availablePaymentMethods === void 0 ? void 0 : availablePaymentMethods.filter(function(el) {
        return defaultPayments === null || defaultPayments === void 0 ? void 0 : defaultPayments.includes(el === null || el === void 0 ? void 0 : el.methodCode);
    });
    var reducedPaymentData = _object_spread_props(_object_spread({}, paymentData), {
        orderPayment: _object_spread_props(_object_spread({}, paymentData === null || paymentData === void 0 ? void 0 : paymentData.orderPayment), {
            orderOnBillPayment: _object_spread_props(_object_spread({}, paymentData === null || paymentData === void 0 ? void 0 : (ref7 = paymentData.orderPayment) === null || ref7 === void 0 ? void 0 : ref7.orderOnBillPayment), {
                availablePaymentMethods: oldAvailablePaymentMethods
            })
        })
    });
    return reducedPaymentData;
};
export { getPatchPayload, getErrorStatusText, getBSNewMopMobile };
