import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { usePatchCustomer, ErrorCodes, getCustomerIdentification, errorMock } from "@vfit/consumer/data-access";
import { useEffect } from "react";
import { LoggerInstance } from "@vfit/shared/providers";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyMobile.context";
import { getErrorStatusText, getPatchPayload } from "../UseCustomerFlow/useCustomerFlow.utils";
import { ID_FLOWS } from "../../checkout.constants";
import { handleCRMErrorCodes, retrieveOwningIndividual } from "../../checkout.utils";
export var useUpdateUserFlow = function() {
    var ref = useCheckout(), product = ref.product, currentStepKey = ref.currentStepKey, customerFlow = ref.customerFlow, owningData = ref.owningData, setCustomerFlow = ref.setCustomerFlow;
    var ref1 = usePatchCustomer(), patchCustomerMutate = ref1.mutate, patchCustomer = ref1.data, isErrorPatchCustomer = ref1.isError, isSuccessPatchCustomer = ref1.isSuccess, patchCustomerError = ref1.error;
    var checkPatchCustomer = function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6;
        var countries = getFromLocalStorageByKey("getCountry");
        var customerData = getFromLocalStorageByKey("customerData");
        if (!customerData || !countries) return false;
        var retrivedIndividual = retrieveOwningIndividual(customerData === null || customerData === void 0 ? void 0 : customerData[0], countries);
        var retrievedIdentification = getCustomerIdentification(retrivedIndividual);
        var owningDataIdentification = getCustomerIdentification(owningData.owningIndividual);
        var owningDataContactMedium = (owningData === null || owningData === void 0 ? void 0 : (ref = owningData.owningIndividual) === null || ref === void 0 ? void 0 : ref.contactMedium) || [];
        var retrievedCustomerDataContactMedium = (customerData === null || customerData === void 0 ? void 0 : (ref1 = customerData[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.owningIndividual) === null || ref2 === void 0 ? void 0 : ref2.contactMedium) || [];
        var owningDataEmail = (owningDataContactMedium === null || owningDataContactMedium === void 0 ? void 0 : (ref3 = owningDataContactMedium[0]) === null || ref3 === void 0 ? void 0 : ref3.emailAddress) || "";
        var retrievedCustomerDataEmail = (retrievedCustomerDataContactMedium === null || retrievedCustomerDataContactMedium === void 0 ? void 0 : (ref4 = retrievedCustomerDataContactMedium[0]) === null || ref4 === void 0 ? void 0 : ref4.emailAddress) || "";
        var owningDataPhoneNumber = (owningDataContactMedium === null || owningDataContactMedium === void 0 ? void 0 : (ref5 = owningDataContactMedium[1]) === null || ref5 === void 0 ? void 0 : ref5.phoneNumber) || "";
        var retrievedCustomerDataPhoneNumber = (retrievedCustomerDataContactMedium === null || retrievedCustomerDataContactMedium === void 0 ? void 0 : (ref6 = retrievedCustomerDataContactMedium[1]) === null || ref6 === void 0 ? void 0 : ref6.phoneNumber) || "";
        if (!(retrievedIdentification === null || retrievedIdentification === void 0 ? void 0 : retrievedIdentification.identificationNumber) || !retrievedIdentification.expirationDate) return false;
        return (retrievedIdentification === null || retrievedIdentification === void 0 ? void 0 : retrievedIdentification.identificationNumber) !== (owningDataIdentification === null || owningDataIdentification === void 0 ? void 0 : owningDataIdentification.identificationNumber) || (retrievedIdentification === null || retrievedIdentification === void 0 ? void 0 : retrievedIdentification.expirationDate) !== (owningDataIdentification === null || owningDataIdentification === void 0 ? void 0 : owningDataIdentification.expirationDate) || (retrievedIdentification === null || retrievedIdentification === void 0 ? void 0 : retrievedIdentification.nationality) !== (owningDataIdentification === null || owningDataIdentification === void 0 ? void 0 : owningDataIdentification.nationality) || retrievedCustomerDataPhoneNumber !== owningDataPhoneNumber || retrievedCustomerDataEmail !== owningDataEmail;
    };
    useEffect(function() {
        if (isSuccessPatchCustomer && patchCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                patchCustomer: _object_spread_props(_object_spread({}, customerFlow.patchCustomer), {
                    data: patchCustomer,
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                }),
                customerInfo: _object_spread_props(_object_spread({}, customerFlow.customerInfo), {
                    data: patchCustomer === null || patchCustomer === void 0 ? void 0 : patchCustomer[0],
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                })
            }));
            localStorage.setItem("customerData", JSON.stringify([
                patchCustomer
            ]));
        }
    }, [
        isSuccessPatchCustomer
    ]);
    useEffect(function() {
        if (isErrorPatchCustomer) {
            var ref, ref1, ref2;
            var headerErrorCode = (patchCustomerError === null || patchCustomerError === void 0 ? void 0 : (ref = patchCustomerError.headers) === null || ref === void 0 ? void 0 : ref.get("ERR-errorCode")) || "";
            var backendError = (patchCustomerError === null || patchCustomerError === void 0 ? void 0 : (ref1 = patchCustomerError.headers) === null || ref1 === void 0 ? void 0 : ref1.get("ERR-backendErrorCode")) || "";
            var backendMessage = ((ref2 = patchCustomerError.headers) === null || ref2 === void 0 ? void 0 : ref2.get("ERR-backendErrorMessage")) || "";
            var errorCode = headerErrorCode === ErrorCodes.DIGITAL_CRM_CODE ? handleCRMErrorCodes(backendError) : headerErrorCode;
            LoggerInstance.log("PatchCustomer", headerErrorCode, backendError, backendMessage, errorCode);
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                patchCustomer: _object_spread_props(_object_spread({}, customerFlow.patchCustomer), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("patchCustomer", {
                        status: patchCustomerError.status,
                        statusText: getErrorStatusText(patchCustomerError.status, errorCode),
                        url: ""
                    }, backendError, errorCode, backendMessage)
                })
            }));
        }
    }, [
        isErrorPatchCustomer
    ]);
    useEffect(function() {
        var checkStep = currentStepKey === ID_FLOWS.PORTABILITY || (product === null || product === void 0 ? void 0 : product.isWinback) && currentStepKey === ID_FLOWS.PORTABILITY_NUMBER || (product === null || product === void 0 ? void 0 : product.isRequiredPortability) && currentStepKey === ID_FLOWS.PORTABILITY_NUMBER || (product === null || product === void 0 ? void 0 : product.skipMnp) && !product.isEsimOnly && currentStepKey === ID_FLOWS.RECOGNITION_ESIM_SKIP_MNP || (product === null || product === void 0 ? void 0 : product.skipMnp) && product.isEsimOnly && currentStepKey === ID_FLOWS.RECOGNITION_CARD_SKIP_MNP;
        if (checkPatchCustomer() && checkStep) {
            patchCustomerMutate(getPatchPayload(owningData));
        }
    }, [
        owningData,
        currentStepKey
    ]);
    return null;
};
