export var ErrorCodes;
(function(ErrorCodes) {
    ErrorCodes["FISCAL_CODE_ERROR"] = "100";
    ErrorCodes["FISCAL_CODE_UNDERAGE"] = "101";
    ErrorCodes["FIND_CALLER_ERROR"] = "102";
    ErrorCodes["CAMPAINGS_ERROR"] = "CAMPAGNS_ERROR";
    ErrorCodes["CREATE_CUSTOMER_GENERIC_ERROR"] = "GENERIC_ERROR";
    ErrorCodes["CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT"] = "DUPLICATE_DOCUMENT_NUMBER";
    ErrorCodes["CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT"] = "DUPLICATE_FISCAL_CODE";
    ErrorCodes["CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT"] = "DUPLICATE_PHONE_NUMBER";
    ErrorCodes["CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT"] = "DUPLICATE_EMAIL";
    ErrorCodes["CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT"] = "DUPLICATE_CONTACT";
    ErrorCodes["DIGITAL_CRM_CODE"] = "DIGITAL_CRM_CODE";
    ErrorCodes["CREATE_CUSTOMER_MISSING_NATION_ADDRESS"] = "PostCreateCustomerServiceCustR7_missingMandatoryParameter_OwningIndividual.nation";
    ErrorCodes["CUSTOMER_ALREADY_PRESENT"] = "ContactAlreadyExists";
    ErrorCodes["MIGRATION_CODE_ERROR"] = "MIGRATION_CODE_ERROR";
    ErrorCodes["MODIFY_PHONE_NUMBER_PRODUCT_ERROR"] = "modifyPhoneNumberProductQuery";
    ErrorCodes["BILLING_DELIVERY_ERROR"] = "BILLING_DELIVERY_ERROR";
    ErrorCodes["BILLING_ERROR"] = "BILLING_ERROR";
    ErrorCodes["SHIPPING_ERROR"] = "SHIPPING_ERROR";
    ErrorCodes["NUMBER_ERROR"] = "NUMBER_ERROR";
    ErrorCodes["CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR"] = "CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR";
    ErrorCodes["ERROR_ON_PAYMENT"] = "mode=erroronpayment";
    ErrorCodes["MODE_BACK_SIA"] = "mode=urlback";
    ErrorCodes["MODE_BACK_SIA_INSTANT"] = "mode=urlbackinstant";
    ErrorCodes["MODE_DONE_SIA_INSTANT"] = "mode=urlbackinstant";
    ErrorCodes["MODE_BACK_SIA_RECURRENT"] = "mode=urlbackrecurrent";
    ErrorCodes["MODE_DONE_SIA_RECURRENT"] = "mode=urldonerecurrent";
    ErrorCodes["URL_FORWARD"] = "mode=urldoneonbillcapture";
    ErrorCodes["MODE_RESET_USER"] = "mode=reset";
    ErrorCodes["ERROR_ON_PAYMENT_GENERIC"] = "ERROR_ON_PAYMENT_GENERIC";
    ErrorCodes["ERROR_ON_PAYMENT_SOFT_KO"] = "softko";
    ErrorCodes["ERROR_ON_PAYMENT_SOFT_TIMEOUT_KO"] = "timeoutSoftKo";
    ErrorCodes["ERROR_ON_PAYMENT_HARD_KO"] = "hardko";
    ErrorCodes["ERROR_ON_PAYMENT_HARD_TIMEOUT_KO"] = "timeouthardko";
    ErrorCodes["ERROR_ON_PAYMENT_PAYMEAN_ALREADY_EXIST"] = "paymeanalreadyexist";
    ErrorCodes["ERROR_ON_PAYMENT_PAYMEAN_ALREADY_EXIST_FOR_SAME_BANK_CODE"] = "paymeanalreadyexistforsamebankcode";
    ErrorCodes["ERROR_ON_PAYMENT_PAYMEAN_REJECTED_OR_CANCELLED"] = "mandaterejectedorcancelled";
    ErrorCodes["ERROR_ON_PAYMENT_IBAN_VALIDATION_FAILED"] = "ibanvalidationfailed";
    ErrorCodes["ERROR_ON_PAYMENT_TIMEOUT_PROCESSING"] = "timeoutProcessing";
    ErrorCodes["ERROR_ON_LOCKIN"] = "lockinError";
    ErrorCodes["ERROR_RESERVE_EQUIPMENT"] = "reserveEqError";
    ErrorCodes["ASSOCIATE_CUSTOMER_BLOCKED"] = "associateCustomerBlocked";
    ErrorCodes["AUTHORIZATION_ERROR_KO"] = "authorizationError";
    ErrorCodes["PAYMENT_DISTRIBUTION_ERROR"] = "paymentDistributionError";
    ErrorCodes["RESERVE_EQUIPMENT_ERROR"] = "reserveEqError";
    ErrorCodes["SUBMIT"] = "submit";
    ErrorCodes["SUBMIT_WITH_PAYMENT"] = "submitwithpayment";
    ErrorCodes["LIGHT_CREDIT_VETTING"] = "lightcreditvetting";
    ErrorCodes["PAYMEAN"] = "paymean";
    ErrorCodes["PATCH_CUSTOMER"] = "patchCustomer";
    ErrorCodes["REFERENCE_CUSTOMER"] = "customerReference";
    ErrorCodes["RETRIEVE_CUSTOMER"] = "retriveCustomer";
})(ErrorCodes || (ErrorCodes = {}));
export var ICampaignErrorType;
(function(ICampaignErrorType) {
    ICampaignErrorType[ICampaignErrorType["CAMPAIGNS"] = 0] = "CAMPAIGNS";
    ICampaignErrorType[ICampaignErrorType["ACCEPT"] = 1] = "ACCEPT";
    ICampaignErrorType[ICampaignErrorType["GET_SHOPPING_CART"] = 2] = "GET_SHOPPING_CART";
    ICampaignErrorType[ICampaignErrorType["ASSOCIATE_CUSTOMER"] = 3] = "ASSOCIATE_CUSTOMER";
    ICampaignErrorType[ICampaignErrorType["UPDATE_PRODUCT_CHARACTERISTICS"] = 4] = "UPDATE_PRODUCT_CHARACTERISTICS";
})(ICampaignErrorType || (ICampaignErrorType = {}));
