import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { errorMock, useLightCreditVettingMutation, useNotify, usePaymentDistributionMobile, useReserveEquipmentMutation, useSubmitMobileMutation, useUPCAutomaticRecharge } from "@vfit/consumer/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
import { API, getFromLocalStorageByKey, usePostCustomerEligibility } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyMobile.context";
import { useTpoCheck } from "../../../ShoppingCart/hooks/useTpoCheck";
// API FLOW
// Payment distribution
// reserve equipment
// light credit vetting
// modifyPhoneNumberPortability (only if the portIn was choosen in checkout flow)
// postCustomer
// submit
export var useCheckoutAutoWalletFlow = function() {
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var ref = useCheckout(), portability = ref.portability, portabilityStatus = ref.portabilityStatus, setPortabilityStatus = ref.setPortabilityStatus;
    // setting internal hook state
    var ref1 = useState(false), isSuccess = ref1[0], setIsSuccess = ref1[1];
    var ref2 = useState(true), isLoading = ref2[0], setIsLoading = ref2[1];
    var ref3 = useState(false), isError = ref3[0], setIsError = ref3[1];
    var ref4 = useState({
        errorMessage: "",
        error: undefined
    }), error = ref4[0], setError = ref4[1];
    var queryClient = useQueryClient();
    var modifyPhoneNumberProductError = queryClient.getQueryData("modifyPhoneNumberProductQuery");
    var submitError = queryClient.getQueryData("submitQuery");
    var ref5 = useUPCAutomaticRecharge(), mutateUPC = ref5.mutate, isLoadingUPC = ref5.isLoading, isSuccessUPC = ref5.isSuccess, isErrorUPC = ref5.isError;
    // (2) Check eligibility
    var ref6 = useTpoCheck(), isTpoEligible = ref6.isEligible, isLoadingCheck = ref6.isLoadingCheck, token = ref6.token;
    // (3) calling hook mutation
    var paymentDistributionMutation = usePaymentDistributionMobile();
    // (4) calling hook mutation
    var reserveEquipmentMutation = useReserveEquipmentMutation();
    // (5) calling hook mutation or lightCreditVettingMutation
    var lightCreditVettingMutation = useLightCreditVettingMutation();
    // (6) calling hook mutation final
    var submitMutation = useSubmitMobileMutation();
    var notifyMutation = useNotify();
    // (7) post submit eligibility
    var ref7 = usePostCustomerEligibility(), isLoadingPostCustomerEligibility = ref7.isLoading, isSuccessPostCustomerEligibility = ref7.isSuccess, isErrorPostCustomerEligibility = ref7.isError, mutatePostCustomerEligibility = ref7.mutate;
    useEffect(function() {
        if (!isLoadingPostCustomerEligibility) {
            if (isSuccessPostCustomerEligibility || isErrorPostCustomerEligibility) {
                setIsError(false);
                setIsLoading(false);
                setIsSuccess(true);
            }
        }
    }, [
        isSuccessPostCustomerEligibility,
        isLoadingPostCustomerEligibility,
        isErrorPostCustomerEligibility, 
    ]);
    /**
   * After Submit hook to finalize the flow
   * setting internal state isLoading=false && isSuccess=true
   */ useEffect(function() {
        if (submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isSuccess) {
            if (isTpoEligible) {
                LoggerInstance.log("TPO::Eligible");
                if (token) {
                    LoggerInstance.log("TPO::Eligible with TOKEN");
                    mutatePostCustomerEligibility({
                        t: token,
                        idOrder: shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id
                    });
                } else {
                    LoggerInstance.log("TPO::Eligible without TOKEN");
                    setIsLoading(false);
                    setIsSuccess(false);
                    setIsError(true);
                }
            } else {
                LoggerInstance.log("TPO:: NOT Eligible");
                setIsError(false);
                setIsLoading(false);
                setIsSuccess(true);
            }
        } else if (submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isError) {
            setIsLoading(false);
            setIsSuccess(false);
            setIsError(true);
            if (submitError) setError(submitError);
        }
    }, [
        submitMutation.isSuccess,
        submitMutation.isLoading,
        submitMutation.isError,
        submitError
    ]);
    /**
   * Submit for portability condition
   */ useEffect(function() {
        if ((portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isSuccess) && (portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isStartFinalPortIn) && (lightCreditVettingMutation === null || lightCreditVettingMutation === void 0 ? void 0 : lightCreditVettingMutation.isSuccess) && !(submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isSuccess) && !(submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isLoading) && !(submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isError)) {
            submitMutation.mutate();
        } else if (portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isError) {
            setIsLoading(false);
            setIsSuccess(false);
            setIsError(true);
            setError(modifyPhoneNumberProductError);
        }
    }, [
        portabilityStatus,
        lightCreditVettingMutation === null || lightCreditVettingMutation === void 0 ? void 0 : lightCreditVettingMutation.isSuccess,
        submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isSuccess,
        submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isLoading, 
    ]);
    useEffect(function() {
        if (!lightCreditVettingMutation.isLoading) {
            if (lightCreditVettingMutation.isSuccess) {
                var ref;
                if ((portability === null || portability === void 0 ? void 0 : portability.portabilityWant) && (portability === null || portability === void 0 ? void 0 : (ref = portability.portabilityOtp) === null || ref === void 0 ? void 0 : ref.isVerified) && (portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isSuccessCheck)) {
                    setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                        isStartFinalPortIn: true
                    }));
                } else if (!(portability === null || portability === void 0 ? void 0 : portability.portabilityWant)) {
                    submitMutation.mutate();
                }
            } else if (lightCreditVettingMutation.isError) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                if (lightCreditVettingMutation === null || lightCreditVettingMutation === void 0 ? void 0 : lightCreditVettingMutation.error) setError(errorMock("lightCreditVetting", lightCreditVettingMutation.error));
            }
        }
    }, [
        lightCreditVettingMutation.isLoading,
        lightCreditVettingMutation.isSuccess,
        lightCreditVettingMutation.isError,
        portability, 
    ]);
    useEffect(function() {
        if (!reserveEquipmentMutation.isLoading) {
            if (reserveEquipmentMutation.isSuccess) {
                var ref;
                if ((reserveEquipmentMutation === null || reserveEquipmentMutation === void 0 ? void 0 : (ref = reserveEquipmentMutation.data) === null || ref === void 0 ? void 0 : ref.status) === "KO") {
                    setIsLoading(false);
                    setIsSuccess(false);
                    setIsError(true);
                    setError(errorMock("reserveEquipment", {
                        status: "200",
                        statusText: "KO",
                        url: API.RESERVE_EQUIPMENT
                    }, "KO"));
                } else {
                    lightCreditVettingMutation.mutate();
                }
            } else if (reserveEquipmentMutation.isError) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                if (reserveEquipmentMutation === null || reserveEquipmentMutation === void 0 ? void 0 : reserveEquipmentMutation.error) setError(errorMock("reserveEquipment", reserveEquipmentMutation.error));
            }
        }
    }, [
        reserveEquipmentMutation.isSuccess,
        reserveEquipmentMutation.isError,
        reserveEquipmentMutation.isLoading, 
    ]);
    useEffect(function() {
        if (!paymentDistributionMutation.isLoading) {
            if (paymentDistributionMutation.isSuccess) {
                var ref;
                if ((ref = paymentDistributionMutation.data) === null || ref === void 0 ? void 0 : ref.error) {
                    setIsLoading(false);
                    setIsSuccess(false);
                    setIsError(true);
                    setError(paymentDistributionMutation.data);
                } else {
                    reserveEquipmentMutation.mutate();
                }
            } else if (paymentDistributionMutation.isError) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                if (paymentDistributionMutation === null || paymentDistributionMutation === void 0 ? void 0 : paymentDistributionMutation.error) setError(errorMock("paymentDistribution", paymentDistributionMutation.error));
            }
        }
    }, [
        paymentDistributionMutation.isLoading,
        paymentDistributionMutation.isSuccess,
        paymentDistributionMutation.isError, 
    ]);
    useEffect(function() {
        if (!isLoadingUPC) {
            if (isSuccessUPC) {
                notifyMutation.mutate();
                paymentDistributionMutation.mutate();
            } else if (isErrorUPC) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
            }
        }
    }, [
        isLoadingUPC,
        isSuccessUPC,
        isErrorUPC
    ]);
    useEffect(function() {
        if (!isLoadingCheck) {
            mutateUPC("No");
        }
    }, [
        isLoadingCheck
    ]);
    return {
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError,
        error: error
    };
};
