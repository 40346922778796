import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { errorMock, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { createPayloadMobile, LOGGED_USER_KEYS, useShoppingCartMutation, useSimTypeSelectionService } from "@vfit/consumer/data-access";
import { useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { resetData } from "@vfit/shared/providers";
import { useCheckout } from "../../../iBuyMobile.context";
export var useDigitalBundle = function(param) {
    var enabler = param.enabler, product = param.product;
    var ref = useCheckout(), customerFlow = ref.customerFlow, portabilityStatus = ref.portabilityStatus, setCustomerFlow = ref.setCustomerFlow, setPortabilityStatus = ref.setPortabilityStatus;
    var paymean = customerFlow.paymean;
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(false), isError = ref2[0], setIsError = ref2[1];
    var ref3 = useState(false), isSuccess = ref3[0], setIsSuccess = ref3[1];
    var ref4 = useState(), error = ref4[0], setError = ref4[1];
    var queryClient = useQueryClient();
    var silentLogin = queryClient.getQueryData("silentLogin");
    var isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId) || false;
    var customerDataList = getFromLocalStorageByKey("customerData");
    var customerData = customerDataList === null || customerDataList === void 0 ? void 0 : customerDataList[0];
    var shoppingCartMutation = useShoppingCartMutation();
    var ref5 = useSimTypeSelectionService(), mutateSim = ref5.mutate, isErrorSim = ref5.isError, isSuccessSim = ref5.isSuccess, dataSimError = ref5.error;
    useEffect(function() {
        if (isSuccessSim) {
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                portInData: undefined,
                isLoadingCheck: false,
                isSuccessCheck: false,
                isErrorCheck: false,
                isStartPortIn: false
            }));
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                billingAccount: _object_spread_props(_object_spread({}, customerFlow.billingAccount), {
                    error: undefined,
                    isLoading: false,
                    isSuccess: false,
                    isError: false
                }),
                paymean: _object_spread_props(_object_spread({}, customerFlow.paymean), {
                    error: undefined,
                    isLoading: false,
                    isSuccess: false,
                    isError: false
                }),
                acceptedFallbackOffer: true
            }));
        }
    }, [
        isSuccessSim
    ]);
    useEffect(function() {
        if (shoppingCartMutation.isSuccess && shoppingCartMutation.data) {
            mutateSim({
                cartId: shoppingCartMutation.data.id
            });
        }
    }, [
        shoppingCartMutation.isSuccess
    ]);
    useEffect(function() {
        if (enabler && (product === null || product === void 0 ? void 0 : product.offerId) && (product === null || product === void 0 ? void 0 : product.planId.toString())) {
            var payload = createPayloadMobile(product === null || product === void 0 ? void 0 : product.offerId, product === null || product === void 0 ? void 0 : product.planId.toString(), isLoggedUser, (customerData === null || customerData === void 0 ? void 0 : customerData.id) || "", product === null || product === void 0 ? void 0 : product.isRequiredPortability, (product === null || product === void 0 ? void 0 : product.isRequiredPortability) ? {
                dummyNumber: product === null || product === void 0 ? void 0 : product.portabilityDummyNumber,
                dummyOperator: product === null || product === void 0 ? void 0 : product.portabilityDummyOperator
            } : undefined);
            if (payload) {
                setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                    associateCustomer: {
                        isLoading: false,
                        isSuccess: false,
                        isError: false
                    },
                    paymean: {
                        isLoading: false,
                        isSuccess: false,
                        isError: false
                    },
                    billingAccount: {
                        isLoading: false,
                        isSuccess: false,
                        isError: false
                    }
                }));
                resetData(queryClient, [
                    "associateCustomer",
                    "paymentData",
                    "billingAccount",
                    "payMean",
                    "updateProductChatacteristics",
                    "doUpdateProductCharacteristic",
                    "checkPortInFeasibilityII", 
                ]);
                shoppingCartMutation.mutate(payload);
                setIsLoading(true);
            } else setIsError(true);
        }
    }, [
        enabler
    ]);
    useEffect(function() {
        if (shoppingCartMutation.isError) {
            setIsLoading(false);
            setIsError(true);
            setError(errorMock("shoppingCart", shoppingCartMutation.error));
        }
        if (isErrorSim) {
            setIsLoading(false);
            setIsError(true);
            setError(errorMock("simInformation", dataSimError));
        }
    }, [
        shoppingCartMutation.isError,
        isErrorSim
    ]);
    useEffect(function() {
        if (customerFlow.associateCustomer.isError) {
            setIsLoading(false);
            setIsError(true);
            setError(customerFlow.associateCustomer.error);
        }
        if (customerFlow.payment.isError) {
            setIsLoading(false);
            setIsError(true);
            setError(customerFlow.payment.error);
        }
        if (customerFlow.billingAccount.isError) setIsLoading(false);
        if (customerFlow.paymean.isError) {
            setIsLoading(false);
            setIsError(true);
            setError(customerFlow.paymean.error);
        }
        if (customerFlow.updateProductChatacteristics.isError) {
            setIsLoading(false);
            setIsError(true);
            setError(customerFlow.updateProductChatacteristics.error);
        }
    }, [
        customerFlow.associateCustomer.isError,
        customerFlow.payment.isError,
        customerFlow.billingAccount.isError,
        customerFlow.paymean.isError,
        customerFlow.updateProductChatacteristics.isError, 
    ]);
    useEffect(function() {
        if (shoppingCartMutation.isSuccess && isSuccessSim && paymean.isSuccess) {
            setIsLoading(false);
            setIsSuccess(true);
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                acceptedFallbackOffer: false
            }));
        }
    }, [
        shoppingCartMutation,
        isSuccessSim,
        paymean.isSuccess
    ]);
    return {
        isError: isError,
        isLoading: isLoading,
        isSuccess: isSuccess,
        error: error
    };
};
