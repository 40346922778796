import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useTracking } from "@vfit/shared/providers";
import { SoftManagerService } from "@vfit/consumer/providers";
import { ENRG_QSTRING } from "@vfit/consumer/data-access";
import { useQueryClient } from "react-query";
import { getFromLocalStorageByKey, setTrackLink, setTrackView } from "@vfit/shared/data-access";
var TaggingBlank = function(param) {
    var taggingOptions = param.taggingOptions, isDisabledInitialTrack = param.isDisabledInitialTrack;
    var ref, ref1;
    var client = useQueryClient();
    var appSession = getFromLocalStorageByKey("appSession");
    var queryString = new URLSearchParams(window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search);
    var msisdn256 = (ref1 = queryString === null || queryString === void 0 ? void 0 : queryString.get(ENRG_QSTRING.MSISDN)) === null || ref1 === void 0 ? void 0 : ref1.replace(/ /g, "+");
    var trackView = useTracking({
        event: [
            (taggingOptions === null || taggingOptions === void 0 ? void 0 : taggingOptions.event) || "view"
        ],
        event_label: (taggingOptions === null || taggingOptions === void 0 ? void 0 : taggingOptions.eventLabel) || "",
        opts: _object_spread_props(_object_spread({}, taggingOptions), {
            product_category: ""
        }),
        visitorTrackingOpts: _object_spread({}, SoftManagerService(client).getUser(), !(appSession === null || appSession === void 0 ? void 0 : appSession.value) && {
            visitor_id_asset_active: msisdn256
        }),
        disableInitialTrack: isDisabledInitialTrack || false
    }).trackView;
    setTrackView(trackView);
    setTrackLink(trackView);
    return null;
};
export default TaggingBlank;
