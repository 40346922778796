import { API } from "@vfit/shared/data-access";
import { LoggerInstance, fwvClient } from "@vfit/shared/providers";
import { useMutation } from "react-query";
var postCustomerEligibilityService = function(param) {
    var t = param.t, idOrder = param.idOrder;
    return fwvClient.post(API.POST_CUSTOMER_ELIGIBILITY, {
        t: t,
        idOrder: idOrder
    });
};
export var usePostCustomerEligibility = function() {
    return useMutation([
        "confirmOrder"
    ], function(data) {
        return postCustomerEligibilityService(data);
    }, {
        onSuccess: function(data) {
            LoggerInstance.debug(data);
        },
        onError: function(error) {
            LoggerInstance.debug(error);
        }
    });
};
