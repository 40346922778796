import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useMutation, useQueryClient } from "react-query";
import { handleUseQuery, LoggerInstance, nextClient } from "@vfit/shared/providers";
import { errorMock, getQueryStringParams, isMobileProduct } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { KEY } from "./deliveryDetails.models";
/**
 * A getter method to retrieve the delivery code based on the deliveryItems
 * @param deliveryItems
 * @returns
 */ var deliveryCode = function(deliveryItems) {
    if ((deliveryItems === null || deliveryItems === void 0 ? void 0 : deliveryItems.length) > 0) {
        var filteredItem = deliveryItems === null || deliveryItems === void 0 ? void 0 : deliveryItems.find(function(itemToFilter) {
            return itemToFilter === null || itemToFilter === void 0 ? void 0 : itemToFilter.isSelected;
        });
        return (filteredItem === null || filteredItem === void 0 ? void 0 : filteredItem.code) || "S";
    }
    return isMobileProduct() ? "S" : "T";
};
var getDeliveryDetailsServiceV1 = function(cartId, parameters, customOptions) {
    return nextClient.get("".concat(API.ORDER, "/").concat(cartId).concat(API.DELIVERY_DETAILS), _object_spread({
        searchParams: parameters
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
export var getDeliveryDetailsServiceV2 = function(cartId, parameters, customOptions) {
    return nextClient.get("".concat(API.ORDER_V2, "/").concat(cartId).concat(API.DELIVERY_DETAILS), _object_spread({
        searchParams: parameters
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * POST method to call the deliveryDetails api
 * @param cartId
 * @param payload
 * @returns
 */ var postDeliveryDetailsServiceV1 = function(cartId, payload) {
    return nextClient.post("".concat(API.ORDER, "/").concat(cartId).concat(API.DELIVERY_DETAILS), payload);
};
/**
 * POST method to call the deliveryDetails api
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */ var postDeliveryDetailsServiceV2 = function(cartId, payload, customOptions) {
    return nextClient.post("".concat(API.ORDER_V2, "/").concat(cartId).concat(API.DELIVERY_DETAILS), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * POST method to call edit bilingAddress
 * @param billingAccountId
 * @param payload
 * @returns
 */ var editBillingAddressV1 = function(billingAccountId, payload) {
    return nextClient.post("".concat(API.REPLACE_BILLING_ADDRESS, "/").concat(billingAccountId, "/replaceBillingAddress"), payload);
};
/**
 * This is a custome useQuery hook to handle all the delivery Get types.
 * @param key
 * @param enabler
 * @param pickupPoint
 * @param customOptions
 * @returns
 */ export var useDeliveryServiceDetails = function(key, enabler, pickupPoint, customOptions) {
    var queryClient = useQueryClient();
    var ref = getQueryStringParams(queryClient, key, pickupPoint), cartId = ref.cartId, parameters = ref.parameters, queryEnabler = ref.queryEnabler;
    LoggerInstance.debug("KEY", key, "cartid", cartId, "param", parameters, "enabler", queryEnabler);
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : [
        "deliveryDetails".concat(key)
    ];
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            if (!isMobileProduct()) return getDeliveryDetailsServiceV2(cartId, parameters, customOptions);
            return getDeliveryDetailsServiceV1(cartId, parameters, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: queryEnabler && enabler,
            onSuccess: function(data) {
                if (key === KEY.isEditAddress) {
                    var ref, ref1, ref2;
                    localStorage.setItem(KEY.ShippingMethodCode, (data === null || data === void 0 ? void 0 : (ref = data.deliveryDetails) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.shippingOptions) === null || ref2 === void 0 ? void 0 : ref2[0].name) || "ST");
                }
                // Save deliveryDetails response object into local storage.
                LoggerInstance.debug("delivery success", data);
                localStorage.setItem("deliveryDetails".concat(key), JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - deliveryDetails".concat(key, ": "), error);
                localStorage.setItem("deliveryDetails".concat(key), JSON.stringify(errorMock("deliveryDetails".concat(key), error)));
            }
        })
    };
    return handleUseQuery(options);
};
/**
 * This is a custom Mutation hook to handle the delivery POST types.
 * @param pickupPoint
 * @returns
 */ export var usePostDeliveryServiceDetails = function(pickupPoint) {
    var queryClient = useQueryClient();
    return useMutation([
        "postDeliveryServiceDetailsMutation"
    ], function(mutationPayload) {
        var ref = mutationPayload || {}, shippingAddress = ref.shippingAddress, identificationPostMethod = ref.identificationMethod;
        var ref1 = getQueryStringParams(queryClient, KEY.isPost, pickupPoint), cartId = ref1.cartId, parameters = ref1.parameters;
        LoggerInstance.debug("KEY", KEY.isPost, "cartid", cartId, "param", parameters, "enabler");
        var payload = [];
        if (parameters) {
            var getFixedDeliveryItems = function(dItems) {
                var items = [];
                dItems === null || dItems === void 0 ? void 0 : dItems.forEach(function(el) {
                    var ref;
                    if ((el === null || el === void 0 ? void 0 : (ref = el.items) === null || ref === void 0 ? void 0 : ref.length) > 0) {
                        var ref1;
                        el === null || el === void 0 ? void 0 : (ref1 = el.items) === null || ref1 === void 0 ? void 0 : ref1.forEach(function(item) {
                            if (item) items.push(item);
                        });
                    }
                });
                return items;
            };
            var shippingMethodEditAddress = localStorage.getItem(KEY.ShippingMethodCode);
            localStorage.removeItem(KEY.ShippingMethodCode);
            var getItemPayload = function(param) {
                var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
                var deliveryMethod = param.deliveryMethod, identificationMethod = param.identificationMethod, recipientAddress = param.recipientAddress, deliveryItems = param.deliveryItems, recipientDetails = param.recipientDetails;
                var shippingMethodCode = shippingMethodEditAddress || (deliveryItems === null || deliveryItems === void 0 ? void 0 : (ref = deliveryItems[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.details) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.shippingOptions) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : ref4.name) || "ST";
                var pickUpDetails = (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) ? {
                    id: pickupPoint.storeId,
                    addressId: ((ref5 = pickupPoint) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.locationAddress) === null || ref6 === void 0 ? void 0 : ref6.id) || ((ref7 = pickupPoint) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.storeAddress) === null || ref8 === void 0 ? void 0 : ref8.id)
                } : {};
                var indetificationMethodCode = identificationMethod;
                if (identificationPostMethod) {
                    var ref10, ref11;
                    indetificationMethodCode = ((ref11 = (ref10 = identificationPostMethod) === null || ref10 === void 0 ? void 0 : ref10.find(function(iD) {
                        return iD.isSelected;
                    })) === null || ref11 === void 0 ? void 0 : ref11.code) || "CO";
                }
                return {
                    deliveryMethodCode: deliveryMethod,
                    shippingMethodCode: shippingMethodCode,
                    indetificationMethodCode: indetificationMethodCode,
                    recipientDetails: {
                        recipientAddress: _object_spread({
                            id: recipientAddress === null || recipientAddress === void 0 ? void 0 : recipientAddress.id
                        }, (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) && recipientAddress),
                        contactPhoneNumber: recipientDetails === null || recipientDetails === void 0 ? void 0 : recipientDetails.contactPhoneNumber,
                        contactEmailAddress: recipientDetails === null || recipientDetails === void 0 ? void 0 : recipientDetails.contactEmailAddress,
                        recipientName: {
                            formattedName: recipientDetails === null || recipientDetails === void 0 ? void 0 : (ref9 = recipientDetails.recipientName) === null || ref9 === void 0 ? void 0 : ref9.formattedName
                        },
                        notes: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.note
                    },
                    pickUpDetails: pickUpDetails,
                    deliveryItems: getFixedDeliveryItems(deliveryItems)
                };
            };
            if (Array.isArray(parameters)) {
                parameters.forEach(function(param) {
                    payload.push(_object_spread({}, getItemPayload(param)));
                });
            } else {
                payload.push(_object_spread({}, getItemPayload(parameters)));
            }
        }
        return postDeliveryDetailsServiceV2(cartId, payload);
    }, {
        onSuccess: function(data) {
            var ref;
            var parsedData = data ? JSON.stringify(data) : "";
            if (data === null || data === void 0 ? void 0 : (ref = data.recipientDetails) === null || ref === void 0 ? void 0 : ref.notes) {
                var ref1;
                localStorage.setItem("deliveryNote", (data === null || data === void 0 ? void 0 : (ref1 = data.recipientDetails) === null || ref1 === void 0 ? void 0 : ref1.notes) || "");
            }
            localStorage.setItem("postDeliveryServiceDetails", parsedData);
            queryClient.setQueryData("postDeliveryServiceDetails", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - postDeliveryServiceDetails: ", error);
            queryClient.setQueryData("postDeliveryServiceDetails", errorMock("postDeliveryServiceDetails", error));
            localStorage.setItem("postDeliveryServiceDetails", JSON.stringify(errorMock("postDeliveryServiceDetails", error)));
        }
    });
};
/**
 * Delivery Base V1 hook
 * @param isEsim
 * @param customOptions
 * @returns
 */ export var useDeliveryBaseV1 = function(isEsim, customOptions) {
    var ref, ref1, ref2, ref3;
    var key = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.keyDependency) ? (ref = customOptions.keyDependency) === null || ref === void 0 ? void 0 : ref[0] : "deliveryDetailsIsBase";
    var keysDependency = [
        key
    ];
    var customerData = getFromLocalStorageByKey("customerData");
    var findCaller = getFromLocalStorageByKey("findCaller");
    var patchCustomer = getFromLocalStorageByKey("patchCustomer");
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref1 = customerData[0]) === null || ref1 === void 0 ? void 0 : ref1.id) || (patchCustomer === null || patchCustomer === void 0 ? void 0 : (ref2 = patchCustomer.owningIndividual) === null || ref2 === void 0 ? void 0 : ref2.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref3 = findCaller.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id);
    var queryEnabler = !!cartId && !!customerId;
    var enabled = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? queryEnabler && customOptions.enabled : queryEnabler;
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var parameters = _object_spread_props(_object_spread({
                salesChannel: "selfService"
            }, !isEsim && {
                mode: "loadConfiguration"
            }), {
                customerId: customerId,
                filter: "All",
                lob: "WS"
            });
            return getDeliveryDetailsServiceV1(cartId, parameters, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: enabled,
            onSuccess: function(data) {
                LoggerInstance.debug("delivery success", data);
                localStorage.setItem("".concat(key), JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - ".concat(key, ": "), error);
                localStorage.setItem("".concat(key), JSON.stringify(errorMock("".concat(key), error)));
            }
        })
    };
    return handleUseQuery(options);
};
/**
 * Delivery Base V2 hook
 * @param customOptions
 * @returns
 */ export var useDeliveryBaseV2 = function(customOptions) {
    var ref, ref1, ref2, ref3;
    var key = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.keyDependency) ? (ref = customOptions.keyDependency) === null || ref === void 0 ? void 0 : ref[0] : "deliveryDetailsIsBase";
    var keysDependency = [
        key
    ];
    var customerData = getFromLocalStorageByKey("customerData");
    var findCaller = getFromLocalStorageByKey("findCaller");
    var patchCustomer = getFromLocalStorageByKey("patchCustomer");
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref1 = customerData[0]) === null || ref1 === void 0 ? void 0 : ref1.id) || (patchCustomer === null || patchCustomer === void 0 ? void 0 : (ref2 = patchCustomer.owningIndividual) === null || ref2 === void 0 ? void 0 : ref2.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref3 = findCaller.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id);
    var queryEnabler = !!cartId && !!customerId;
    var enabled = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? queryEnabler && customOptions.enabled : queryEnabler;
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var parameters = {
                salesChannel: "selfService",
                mode: "loadConfiguration",
                customerId: customerId,
                filter: "All",
                lob: "WL",
                identificationMethod: "NA"
            };
            return getDeliveryDetailsServiceV2(cartId, parameters, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: enabled,
            onSuccess: function(data) {
                LoggerInstance.debug("delivery success", data);
                localStorage.setItem("".concat(key), JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - ".concat(key, ": "), error);
                localStorage.setItem("".concat(key), JSON.stringify(errorMock("".concat(key), error)));
            }
        })
    };
    return handleUseQuery(options);
};
/**
 * Delivery Advance V1 hook
 * @param identificationMethod
 * @param customOptions
 * @returns
 */ export var useDeliveryAdvanceV1 = function(identificationMethod, customOptions) {
    var ref, ref1, ref2, ref3;
    var key = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.keyDependency) ? (ref = customOptions.keyDependency) === null || ref === void 0 ? void 0 : ref[0] : "deliveryDetailsIsAdvance";
    var keysDependency = [
        key
    ];
    var customerData = getFromLocalStorageByKey("customerData");
    var findCaller = getFromLocalStorageByKey("findCaller");
    var patchCustomer = getFromLocalStorageByKey("patchCustomer");
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref1 = customerData[0]) === null || ref1 === void 0 ? void 0 : ref1.id) || (patchCustomer === null || patchCustomer === void 0 ? void 0 : (ref2 = patchCustomer.owningIndividual) === null || ref2 === void 0 ? void 0 : ref2.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref3 = findCaller.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id);
    var queryEnabler = !!cartId && !!customerId;
    var enabled = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? queryEnabler && customOptions.enabled : queryEnabler;
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var parameters = {
                salesChannel: "selfService",
                mode: "loadConfiguration",
                identificationMethod: identificationMethod,
                customerId: customerId,
                filter: "All",
                lob: "WS"
            };
            return getDeliveryDetailsServiceV1(cartId, parameters, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: enabled,
            onSuccess: function(data) {
                LoggerInstance.debug("delivery success", data);
                localStorage.setItem("".concat(key), JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - ".concat(key, ": "), error);
                localStorage.setItem("".concat(key), JSON.stringify(errorMock("".concat(key), error)));
            }
        })
    };
    return handleUseQuery(options);
};
/**
 * Delivery Advance V2 hook
 * @param identificationMethod
 * @param addressId
 * @param deliveryMethod
 * @param customOptions
 * @returns
 */ export var useDeliveryAdvanceV2 = function(identificationMethod, addressId, deliveryMethod, customOptions) {
    var ref, ref1, ref2, ref3;
    var key = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.keyDependency) ? (ref = customOptions.keyDependency) === null || ref === void 0 ? void 0 : ref[0] : "deliveryDetailsIsAdvance";
    var keysDependency = [
        key
    ];
    var customerData = getFromLocalStorageByKey("customerData");
    var findCaller = getFromLocalStorageByKey("findCaller");
    var patchCustomer = getFromLocalStorageByKey("patchCustomer");
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref1 = customerData[0]) === null || ref1 === void 0 ? void 0 : ref1.id) || (patchCustomer === null || patchCustomer === void 0 ? void 0 : (ref2 = patchCustomer.owningIndividual) === null || ref2 === void 0 ? void 0 : ref2.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref3 = findCaller.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id);
    var queryEnabler = !!cartId && !!customerId;
    var enabled = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? queryEnabler && customOptions.enabled : queryEnabler;
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var parameters = {
                salesChannel: "selfService",
                identificationMethod: identificationMethod,
                addressId: addressId,
                deliveryMethod: deliveryMethod,
                filter: "All",
                lob: "WL"
            };
            return getDeliveryDetailsServiceV2(cartId, parameters, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: enabled,
            onSuccess: function(data) {
                LoggerInstance.info("delivery success", data);
                localStorage.setItem("".concat(key), JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - ".concat(key, ": "), error);
                localStorage.setItem("".concat(key), JSON.stringify(errorMock("".concat(key), error)));
            }
        })
    };
    return handleUseQuery(options);
};
/**
 * Delivery Evolved V2 hook
 * @param shippingAddress
 * @param pickupPoint
 * @param customOptions
 * @returns
 */ export var useDeliveryEvolvedV2 = function(shippingAddress, pickupPoint, customOptions) {
    var ref, ref1, ref2, ref3;
    var key = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.keyDependency) ? (ref = customOptions.keyDependency) === null || ref === void 0 ? void 0 : ref[0] : "deliveryDetailsIsEvolvedMobile";
    var keysDependency = [
        key
    ];
    var customerData = getFromLocalStorageByKey("customerData");
    var findCaller = getFromLocalStorageByKey("findCaller");
    var patchCustomer = getFromLocalStorageByKey("patchCustomer");
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref1 = customerData[0]) === null || ref1 === void 0 ? void 0 : ref1.id) || (patchCustomer === null || patchCustomer === void 0 ? void 0 : (ref2 = patchCustomer.owningIndividual) === null || ref2 === void 0 ? void 0 : ref2.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref3 = findCaller.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id);
    var queryEnabler = !!cartId && !!customerId;
    var enabled = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? queryEnabler && customOptions.enabled : queryEnabler;
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var ref, ref1;
            var deliveryDetailsAdvanced = getFromLocalStorageByKey("deliveryDetailsIsAdvance");
            if (deliveryDetailsAdvanced && !Array.isArray(deliveryDetailsAdvanced)) {
                deliveryDetailsAdvanced = [
                    deliveryDetailsAdvanced
                ];
            }
            var deliveryData = deliveryDetailsAdvanced === null || deliveryDetailsAdvanced === void 0 ? void 0 : deliveryDetailsAdvanced[0];
            var deliveryDetails = deliveryData.deliveryDetails, identificationDetails = deliveryData.identificationDetails;
            var selectedMethod = identificationDetails === null || identificationDetails === void 0 ? void 0 : identificationDetails.filter(function(item) {
                return item.isSelected === true;
            });
            var identificationMethod = (selectedMethod === null || selectedMethod === void 0 ? void 0 : (ref = selectedMethod[0]) === null || ref === void 0 ? void 0 : ref.code) || "NA";
            var deliveryMethod = (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) ? ((ref1 = pickupPoint) === null || ref1 === void 0 ? void 0 : ref1.typePickupPoint) || "PO" : deliveryCode(deliveryDetails);
            var parameters = {
                salesChannel: "selfService",
                mode: "reviewMode",
                identificationMethod: identificationMethod,
                deliveryMethod: deliveryMethod,
                customerId: customerId,
                filter: "All",
                lob: "WL"
            };
            return getDeliveryDetailsServiceV2(cartId, parameters, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: enabled,
            onSuccess: function(data) {
                LoggerInstance.debug("delivery success", data);
                localStorage.setItem("".concat(key), JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - ".concat(key, ": "), error);
                localStorage.setItem("".concat(key), JSON.stringify(errorMock("".concat(key), error)));
            }
        })
    };
    return handleUseQuery(options);
};
/**
 * Delivery Evolved V1 hook
 * @param shippingAddress
 * @param pickupPoint
 * @param isEsim
 * @param customOptions
 * @returns
 */ export var useDeliveryEvolvedV1 = function(shippingAddress, pickupPoint, isEsim, customOptions) {
    var ref, ref1, ref2, ref3;
    var key = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.keyDependency) ? (ref = customOptions.keyDependency) === null || ref === void 0 ? void 0 : ref[0] : "deliveryDetailsIsEvolvedMobile";
    var keysDependency = [
        key
    ];
    var customerData = getFromLocalStorageByKey("customerData");
    var findCaller = getFromLocalStorageByKey("findCaller");
    var patchCustomer = getFromLocalStorageByKey("patchCustomer");
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref1 = customerData[0]) === null || ref1 === void 0 ? void 0 : ref1.id) || (patchCustomer === null || patchCustomer === void 0 ? void 0 : (ref2 = patchCustomer.owningIndividual) === null || ref2 === void 0 ? void 0 : ref2.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref3 = findCaller.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id);
    var queryEnabler = !!cartId && !!customerId;
    var enabled = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? queryEnabler && customOptions.enabled : queryEnabler;
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var ref, ref1, ref2, ref3;
            var deliveryDetailsAdvanced = getFromLocalStorageByKey("deliveryDetailsIsAdvance");
            if (deliveryDetailsAdvanced && !Array.isArray(deliveryDetailsAdvanced)) {
                deliveryDetailsAdvanced = [
                    deliveryDetailsAdvanced
                ];
            }
            var deliveryData = deliveryDetailsAdvanced === null || deliveryDetailsAdvanced === void 0 ? void 0 : deliveryDetailsAdvanced[0];
            var deliveryDetails = deliveryData.deliveryDetails, identificationDetails = deliveryData.identificationDetails;
            var selectedMethod = identificationDetails === null || identificationDetails === void 0 ? void 0 : identificationDetails.filter(function(item) {
                return item.isSelected === true;
            });
            var identification = isEsim ? "VR" : "NA";
            var identificationMethod = (selectedMethod === null || selectedMethod === void 0 ? void 0 : (ref = selectedMethod[0]) === null || ref === void 0 ? void 0 : ref.code) || identification;
            var recipientAddress = (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) ? ((ref1 = pickupPoint) === null || ref1 === void 0 ? void 0 : ref1.locationAddress) || ((ref2 = pickupPoint) === null || ref2 === void 0 ? void 0 : ref2.storeAddress) : shippingAddress;
            var deliveryMethod = (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) ? ((ref3 = pickupPoint) === null || ref3 === void 0 ? void 0 : ref3.typePickupPoint) || "PO" : deliveryCode(deliveryDetails);
            var parameters = {
                salesChannel: "selfService",
                mode: "loadConfiguration",
                identificationMethod: identificationMethod,
                addressId: (recipientAddress === null || recipientAddress === void 0 ? void 0 : recipientAddress.id) || (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId),
                deliveryMethod: deliveryMethod,
                filter: "All",
                lob: "WS"
            };
            return getDeliveryDetailsServiceV1(cartId, parameters, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: enabled,
            onSuccess: function(data) {
                LoggerInstance.debug("delivery success", data);
                localStorage.setItem("".concat(key), JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - ".concat(key, ": "), error);
                localStorage.setItem("".concat(key), JSON.stringify(errorMock("".concat(key), error)));
            }
        })
    };
    return handleUseQuery(options);
};
/**
 * Delivery Post Method
 * @returns
 */ export var usePostDeliveryV1 = function() {
    var queryClient = useQueryClient();
    return useMutation([
        "postDeliveryServiceDetailsMutation"
    ], function(param) {
        var shippingAddress = param.shippingAddress, pickupPoint = param.pickupPoint, isEsim = param.isEsim;
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var deliveryDetailsEvolved = getFromLocalStorageByKey("deliveryDetailsIsEvolvedMobile");
        if (deliveryDetailsEvolved && !Array.isArray(deliveryDetailsEvolved)) {
            deliveryDetailsEvolved = [
                deliveryDetailsEvolved
            ];
        }
        var deliveryData = deliveryDetailsEvolved === null || deliveryDetailsEvolved === void 0 ? void 0 : deliveryDetailsEvolved[0];
        var deliveryItems = deliveryData.deliveryItems, identificationDetails = deliveryData.identificationDetails, recipientDetails = deliveryData.recipientDetails, deliveryDetails = deliveryData.deliveryDetails;
        var selectedMethod = identificationDetails === null || identificationDetails === void 0 ? void 0 : identificationDetails.filter(function(item) {
            return item.isSelected === true;
        });
        var identification = isEsim ? "VR" : "NA";
        var identificationMethodCode = (selectedMethod === null || selectedMethod === void 0 ? void 0 : (ref = selectedMethod[0]) === null || ref === void 0 ? void 0 : ref.code) || identification;
        var recipientAddress = (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) ? ((ref1 = pickupPoint) === null || ref1 === void 0 ? void 0 : ref1.locationAddress) || ((ref2 = pickupPoint) === null || ref2 === void 0 ? void 0 : ref2.storeAddress) : shippingAddress;
        var deliveryMethodCode = (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) ? ((ref3 = pickupPoint) === null || ref3 === void 0 ? void 0 : ref3.typePickupPoint) || "PO" : deliveryCode(deliveryDetails);
        var shippingMethodCode = deliveryDetails === null || deliveryDetails === void 0 ? void 0 : (ref4 = deliveryDetails[0]) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.shippingOptions) === null || ref5 === void 0 ? void 0 : (ref6 = ref5[0]) === null || ref6 === void 0 ? void 0 : ref6.name;
        var pickUpDetails = (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) ? {
            id: pickupPoint.storeId,
            addressId: ((ref7 = pickupPoint) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.locationAddress) === null || ref8 === void 0 ? void 0 : ref8.id) || ((ref9 = pickupPoint) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.storeAddress) === null || ref10 === void 0 ? void 0 : ref10.id)
        } : {};
        var payload = [
            {
                deliveryMethodCode: deliveryMethodCode,
                shippingMethodCode: shippingMethodCode,
                indetificationMethodCode: identificationMethodCode,
                recipientDetails: {
                    recipientAddress: _object_spread({
                        id: recipientAddress === null || recipientAddress === void 0 ? void 0 : recipientAddress.id
                    }, (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) && recipientAddress),
                    contactPhoneNumber: recipientDetails === null || recipientDetails === void 0 ? void 0 : recipientDetails.contactPhoneNumber,
                    contactEmailAddress: recipientDetails === null || recipientDetails === void 0 ? void 0 : recipientDetails.contactEmailAddress,
                    recipientName: {
                        formattedName: recipientDetails === null || recipientDetails === void 0 ? void 0 : (ref11 = recipientDetails.recipientName) === null || ref11 === void 0 ? void 0 : ref11.formattedName
                    },
                    notes: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.note
                },
                pickUpDetails: pickUpDetails,
                deliveryItems: deliveryItems
            }, 
        ];
        return postDeliveryDetailsServiceV1(cartId, payload[0]);
    }, {
        onSuccess: function(data) {
            var parsedData = data ? JSON.stringify(data) : "";
            localStorage.setItem("postDeliveryServiceDetails", parsedData);
            queryClient.setQueryData("postDeliveryServiceDetails", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - postDeliveryServiceDetails: ", error);
            queryClient.setQueryData("postDeliveryServiceDetails", errorMock("postDeliveryServiceDetails", error));
            localStorage.setItem("postDeliveryServiceDetails", JSON.stringify(errorMock("postDeliveryServiceDetails", error)));
        }
    });
};
/**
 * Delivery Post Method V2
 * @returns
 */ export var usePostDeliveryV2 = function() {
    var queryClient = useQueryClient();
    return useMutation([
        "postDeliveryServiceDetailsMutation"
    ], function(param) {
        var shippingAddress = param.shippingAddress, pickupPoint = param.pickupPoint;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var deliveryDetailsEvolved = getFromLocalStorageByKey("deliveryDetailsIsEvolved");
        if (deliveryDetailsEvolved && !Array.isArray(deliveryDetailsEvolved)) {
            deliveryDetailsEvolved = [
                deliveryDetailsEvolved
            ];
        }
        var payload = [];
        var deliveryData = deliveryDetailsEvolved;
        var recipientAddress;
        if (!(pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId)) {
            recipientAddress = shippingAddress;
        } else {
            var ref, ref1;
            recipientAddress = ((ref = pickupPoint) === null || ref === void 0 ? void 0 : ref.locationAddress) || ((ref1 = pickupPoint) === null || ref1 === void 0 ? void 0 : ref1.storeAddress);
        }
        if (deliveryData) {
            var getDeliveryItem = function(deliveryItem) {
                var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
                var deliveryItems = deliveryItem.deliveryItems, identificationDetails = deliveryItem.identificationDetails, recipientDetails = deliveryItem.recipientDetails;
                var selectedMethod = identificationDetails === null || identificationDetails === void 0 ? void 0 : identificationDetails.filter(function(item) {
                    return item.isSelected === true;
                });
                var getDeliveryCode = function(dI) {
                    if ((dI === null || dI === void 0 ? void 0 : dI.length) > 0) {
                        var details = [];
                        dI === null || dI === void 0 ? void 0 : dI.forEach(function(el) {
                            var ref;
                            return details.push((el === null || el === void 0 ? void 0 : (ref = el.details) === null || ref === void 0 ? void 0 : ref[0]) || {});
                        });
                        var filteredItem = details === null || details === void 0 ? void 0 : details.find(function(itemToFilter) {
                            return itemToFilter === null || itemToFilter === void 0 ? void 0 : itemToFilter.isSelected;
                        });
                        return (filteredItem === null || filteredItem === void 0 ? void 0 : filteredItem.code) || "S";
                    }
                    return "T";
                };
                var identificationMethodCode = selectedMethod === null || selectedMethod === void 0 ? void 0 : (ref = selectedMethod[0]) === null || ref === void 0 ? void 0 : ref.code;
                var shippingMethodCode = (deliveryItems === null || deliveryItems === void 0 ? void 0 : (ref1 = deliveryItems[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.details) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.shippingOptions) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : ref5.name) || "ST";
                var pickUpDetails = (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) ? {
                    id: pickupPoint.storeId,
                    addressId: ((ref6 = pickupPoint) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.locationAddress) === null || ref7 === void 0 ? void 0 : ref7.id) || ((ref8 = pickupPoint) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.storeAddress) === null || ref9 === void 0 ? void 0 : ref9.id)
                } : {};
                var getFixedDeliveryItems = function(dItems) {
                    var items = [];
                    dItems === null || dItems === void 0 ? void 0 : dItems.forEach(function(el) {
                        var ref;
                        if ((el === null || el === void 0 ? void 0 : (ref = el.items) === null || ref === void 0 ? void 0 : ref.length) > 0) {
                            var ref1;
                            el === null || el === void 0 ? void 0 : (ref1 = el.items) === null || ref1 === void 0 ? void 0 : ref1.forEach(function(item) {
                                if (item) items.push(item);
                            });
                        }
                    });
                    return items;
                };
                var deliveryItemsFixed = getFixedDeliveryItems(deliveryItems);
                var haveAntType = deliveryItemsFixed === null || deliveryItemsFixed === void 0 ? void 0 : deliveryItemsFixed.some(function(el) {
                    var ref;
                    return ((ref = el.itemType) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "ant";
                });
                var deliveryMethodCode = (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) ? ((ref10 = pickupPoint) === null || ref10 === void 0 ? void 0 : ref10.typePickupPoint) || "PO" : getDeliveryCode(deliveryItems);
                if (haveAntType) deliveryMethodCode = "T";
                return {
                    deliveryMethodCode: deliveryMethodCode,
                    shippingMethodCode: shippingMethodCode,
                    indetificationMethodCode: identificationMethodCode,
                    recipientDetails: {
                        recipientAddress: _object_spread({
                            id: recipientAddress === null || recipientAddress === void 0 ? void 0 : recipientAddress.id
                        }, (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) && recipientAddress),
                        contactPhoneNumber: recipientDetails === null || recipientDetails === void 0 ? void 0 : recipientDetails.contactPhoneNumber,
                        contactEmailAddress: recipientDetails === null || recipientDetails === void 0 ? void 0 : recipientDetails.contactEmailAddress,
                        recipientName: {
                            formattedName: recipientDetails === null || recipientDetails === void 0 ? void 0 : (ref11 = recipientDetails.recipientName) === null || ref11 === void 0 ? void 0 : ref11.formattedName
                        },
                        notes: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.note
                    },
                    pickUpDetails: pickUpDetails,
                    deliveryItems: deliveryItemsFixed
                };
            };
            if (Array.isArray(deliveryData)) {
                deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.forEach(function(delivery) {
                    payload.push(_object_spread({}, getDeliveryItem(delivery)));
                });
            } else {
                payload.push(_object_spread({}, getDeliveryItem(deliveryData)));
            }
        }
        return postDeliveryDetailsServiceV2(cartId, payload);
    }, {
        onSuccess: function(data) {
            var parsedData = data ? JSON.stringify(data) : "";
            localStorage.setItem("postDeliveryServiceDetails", parsedData);
            queryClient.setQueryData("postDeliveryServiceDetails", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - postDeliveryServiceDetails: ", error);
            queryClient.setQueryData("postDeliveryServiceDetails", errorMock("postDeliveryServiceDetails", error));
            localStorage.setItem("postDeliveryServiceDetails", JSON.stringify(errorMock("postDeliveryServiceDetails", error)));
        }
    });
};
/**
 * Delivery Replace billing address
 * @returns
 */ export var useReplaceBillingAddressV1 = function() {
    var queryClient = useQueryClient();
    return useMutation([
        "replaceBillingAddress"
    ], function(param) {
        var billingAccountId = param.billingAccountId, billingAddressId = param.billingAddressId, billingAddressType = param.billingAddressType;
        return editBillingAddressV1(billingAccountId, {
            billingAddressId: billingAddressId,
            billingAddressType: billingAddressType
        });
    }, {
        onSuccess: function(data) {
            var parsedData = data ? JSON.stringify(data) : "";
            localStorage.setItem("postDeliveryServiceDetails", parsedData);
            queryClient.setQueryData("postDeliveryServiceDetails", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - postDeliveryServiceDetails: ", error);
            queryClient.setQueryData("postDeliveryServiceDetails", errorMock("postDeliveryServiceDetails", error));
            localStorage.setItem("postDeliveryServiceDetails", JSON.stringify(errorMock("postDeliveryServiceDetails", error)));
        }
    });
};
