import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { CMS_CONFIG, errorMock, SELECTED_PAYMENT, useAuthorizationOrder, useCmsConfig, useGetPaymean, useLightCreditVettingMutation, useNotify, usePaymentDistributionMobile, useReserveEquipmentMutation, useSubmitMobileMutation, useSubmitWithPaymentMobile, useValidateIbansMobile } from "@vfit/consumer/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
import { EMethodCode, Path } from "@vfit/shared/models";
import { API, getFromLocalStorageByKey, PAGES, usePostCustomerEligibility } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyMobile.context";
import { useTpoCheck } from "../../../ShoppingCart/hooks/useTpoCheck";
// API FLOW
// Check eligibility
// Authorization
// Paymean
// Payment distribution
// validate iban only if is IBAN
// reserve equipment
// light credit vetting
// modifyPhoneNumberPortability (only if the portIn was choosen in checkout flow)
// submit / submitWithPayment
// post eligibility if is TPO e NOT CAMPAIGN
export var useCheckoutFlow = function(param) {
    var ignoreQueryString = param.ignoreQueryString;
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var queryString = window.location.search;
    var ref = useCheckout(), portability = ref.portability, portabilityStatus = ref.portabilityStatus, setPortabilityStatus = ref.setPortabilityStatus;
    var globalConfig = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_CONFIG);
    // setting internal hook state
    var ref1 = useState(false), isSuccess = ref1[0], setIsSuccess = ref1[1];
    var ref2 = useState(true), isLoading = ref2[0], setIsLoading = ref2[1];
    var ref3 = useState(false), isError = ref3[0], setIsError = ref3[1];
    var ref4 = useState({
        errorMessage: "",
        error: undefined
    }), error = ref4[0], setError = ref4[1];
    var queryClient = useQueryClient();
    var authorizationError = queryClient.getQueryData("authorizationError");
    var paymentDistributionError = queryClient.getQueryData("paymentDistribution");
    var reserveEquipmentError = queryClient.getQueryData("reserveEquipment");
    var lightCreditVettingError = queryClient.getQueryData("lightCreditVetting");
    var validateIbanError = queryClient.getQueryData("validateIbansQuery");
    var modifyPhoneNumberProductError = queryClient.getQueryData("modifyPhoneNumberProductQuery");
    var submitError = queryClient.getQueryData("submitQuery");
    // (1) Check eligibility
    var ref5 = useTpoCheck(), isTpoOffer = ref5.isTpoOffer, isInCampaignTpo = ref5.isInCampaignTpo, isLoadingCheck = ref5.isLoadingCheck, isEligible = ref5.isEligible, token = ref5.token;
    // (2) calling hook mutation
    var ref6 = useAuthorizationOrder({
        enabled: false
    }), refetchAuthorization = ref6.refetch, authorizationOrderData = ref6.data, isSuccessAuthorizationOrder = ref6.isSuccess, isErrorAuthorizationOrder = ref6.isError, isLoadingAuthorizationOrder = ref6.isLoading;
    var ref7 = useValidateIbansMobile(), isLoadingValidateIban = ref7.isLoading, mutateValidateIban = ref7.mutate, isErrorValidateIban = ref7.isError, isSuccessValidateIban = ref7.isSuccess;
    var ref8 = useGetPaymean(undefined, globalConfig), isErrorPayMean = ref8.isError, isLoadingPayMean = ref8.isLoading;
    // (3) calling notify
    var notifyMutation = useNotify();
    // (4) calling hook mutation
    var paymentDistributionMutation = usePaymentDistributionMobile();
    var selectedPaymentMethod = localStorage.getItem(SELECTED_PAYMENT);
    // (5) calling hook mutation
    var reserveEquipmentMutation = useReserveEquipmentMutation();
    // (6) calling hook mutation or lightCreditVettingMutation
    var lightCreditVettingMutation = useLightCreditVettingMutation();
    // (7) calling hook mutation final
    var submitCCMutation = useSubmitMobileMutation();
    var submitWithPaymentMutation = useSubmitWithPaymentMobile();
    var submitMutation = (selectedPaymentMethod === null || selectedPaymentMethod === void 0 ? void 0 : selectedPaymentMethod.toLowerCase()) === EMethodCode.CREDIT_CARD.toLowerCase() ? submitCCMutation : submitWithPaymentMutation;
    // (8) post submit eligibility
    var ref9 = usePostCustomerEligibility(), isLoadingPostCustomerEligibility = ref9.isLoading, isSuccessPostCustomerEligibility = ref9.isSuccess, isErrorPostCustomerEligibility = ref9.isError, mutatePostCustomerEligibility = ref9.mutate;
    var isIban = (selectedPaymentMethod === null || selectedPaymentMethod === void 0 ? void 0 : selectedPaymentMethod.toLowerCase()) === EMethodCode.BANK_ACCOUNT.toLowerCase();
    /**
   * Submit for portability condition
   */ useEffect(function() {
        if ((portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isSuccess) && (portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isStartFinalPortIn) && (lightCreditVettingMutation === null || lightCreditVettingMutation === void 0 ? void 0 : lightCreditVettingMutation.isSuccess) && !(submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isSuccess) && !(submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isLoading) && !(submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isError)) {
            submitMutation.mutate();
        } else if (portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isError) {
            setIsLoading(false);
            setIsSuccess(false);
            setIsError(true);
            setError(modifyPhoneNumberProductError);
        }
    }, [
        portabilityStatus,
        lightCreditVettingMutation === null || lightCreditVettingMutation === void 0 ? void 0 : lightCreditVettingMutation.isSuccess,
        submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isSuccess,
        submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isLoading, 
    ]);
    useEffect(function() {
        if (!isLoadingPostCustomerEligibility) {
            if (isSuccessPostCustomerEligibility || isErrorPostCustomerEligibility) {
                setIsError(false);
                setIsLoading(false);
                setIsSuccess(true);
            }
        }
    }, [
        isSuccessPostCustomerEligibility,
        isLoadingPostCustomerEligibility,
        isErrorPostCustomerEligibility, 
    ]);
    /**
   * After Submit hook to finalize the flow
   * setting internal state isLoading=false && isSuccess=true
   */ useEffect(function() {
        if (!submitMutation.isLoading) {
            if (submitMutation.isSuccess) {
                if (isTpoOffer && !isInCampaignTpo) {
                    mutatePostCustomerEligibility({
                        t: token || "",
                        idOrder: shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id
                    });
                } else {
                    setIsError(false);
                    setIsLoading(false);
                    setIsSuccess(true);
                }
            } else if (submitMutation.isError && submitError) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                setError(submitError);
            }
        }
    }, [
        submitMutation.isSuccess,
        submitMutation.isLoading,
        submitMutation.isError,
        submitError
    ]);
    useEffect(function() {
        if (isErrorValidateIban) {
            setIsLoading(false);
            setIsSuccess(false);
            setIsError(true);
            setError(validateIbanError);
        } else if (isSuccessValidateIban) {
            reserveEquipmentMutation.mutate();
        }
    }, [
        isSuccessValidateIban,
        isLoadingValidateIban,
        isErrorValidateIban
    ]);
    /**
   * LightCreditVetting conditioned by portability if chosen
   */ useEffect(function() {
        if (!lightCreditVettingMutation.isLoading) {
            if (lightCreditVettingMutation.isSuccess) {
                LoggerInstance.debug("useCheckoutFlow lightCreditVettingMutation.data", lightCreditVettingMutation.data);
                if ((portability === null || portability === void 0 ? void 0 : portability.portabilityWant) && (portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isSuccessCheck)) {
                    setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                        isStartFinalPortIn: true
                    }));
                } else if (!(portability === null || portability === void 0 ? void 0 : portability.portabilityWant) && !(submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isError)) {
                    submitMutation.mutate();
                }
            } else if (lightCreditVettingMutation.isError && lightCreditVettingError) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                setError(lightCreditVettingError);
            }
        }
    }, [
        lightCreditVettingMutation.isLoading,
        lightCreditVettingMutation.isSuccess,
        lightCreditVettingMutation.isError,
        lightCreditVettingError,
        portability, 
    ]);
    /**
   * handlerPaymentType hook conditioned by reserveEquipmentMutation.data
   */ useEffect(function() {
        if (!reserveEquipmentMutation.isLoading) {
            if (reserveEquipmentMutation.isSuccess) {
                var ref;
                if ((reserveEquipmentMutation === null || reserveEquipmentMutation === void 0 ? void 0 : (ref = reserveEquipmentMutation.data) === null || ref === void 0 ? void 0 : ref.status) === "KO") {
                    setIsLoading(false);
                    setIsSuccess(false);
                    setIsError(true);
                    setError(errorMock("reserveEquipment", {
                        status: "200",
                        statusText: "KO",
                        url: API.RESERVE_EQUIPMENT
                    }, "KO"));
                } else {
                    lightCreditVettingMutation.mutate();
                }
            } else if (reserveEquipmentMutation.isError && reserveEquipmentError) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                setError(reserveEquipmentError);
            }
        }
    }, [
        reserveEquipmentMutation.isSuccess,
        reserveEquipmentMutation.isError,
        reserveEquipmentMutation.isLoading,
        reserveEquipmentError, 
    ]);
    /**
   * ReserveEquipment mutation initializer conditioned by paymentDistributionMutation.data
   */ useEffect(function() {
        if (!paymentDistributionMutation.isLoading) {
            if (paymentDistributionMutation.isSuccess) {
                var ref;
                if ((ref = paymentDistributionMutation.data) === null || ref === void 0 ? void 0 : ref.error) {
                    setIsLoading(false);
                    setIsSuccess(false);
                    setIsError(true);
                    setError(paymentDistributionMutation.data);
                } else if (isIban) {
                    mutateValidateIban();
                } else {
                    reserveEquipmentMutation.mutate();
                }
            } else if (paymentDistributionMutation.isError && (paymentDistributionError === null || paymentDistributionError === void 0 ? void 0 : paymentDistributionError.error)) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                setError(paymentDistributionError);
            }
        }
    }, [
        paymentDistributionMutation.isLoading,
        paymentDistributionMutation.isSuccess,
        paymentDistributionMutation.isError,
        paymentDistributionError, 
    ]);
    /**
   * PaymentDistribution mutation initializer conditioned by authorizationOrderData
   */ useEffect(function() {
        LoggerInstance.debug("queryString", queryString);
        if ((queryString === null || queryString === void 0 ? void 0 : queryString.includes(Path.URL_FORWARD)) || ignoreQueryString) {
            if (isSuccessAuthorizationOrder && !isLoadingAuthorizationOrder) {
                if (authorizationError === null || authorizationError === void 0 ? void 0 : authorizationError.error) {
                    setIsLoading(false);
                    setIsSuccess(false);
                    setIsError(true);
                    setError(authorizationError);
                } else {
                    LoggerInstance.debug("useCheckoutFlow authorizationOrderData : ", authorizationOrderData);
                    LoggerInstance.debug("useCheckoutFlow calling paymentDistributionMutation.mutate()");
                    notifyMutation.mutate();
                    paymentDistributionMutation.mutate();
                }
            } else if (isErrorPayMean && !isLoadingPayMean || isErrorAuthorizationOrder && !isLoadingAuthorizationOrder) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                var paymeanError = getFromLocalStorageByKey("payMean");
                setError(paymeanError);
            }
        }
    }, [
        isLoadingAuthorizationOrder,
        isErrorAuthorizationOrder,
        isSuccessAuthorizationOrder,
        isErrorPayMean, 
    ]);
    useEffect(function() {
        if (!isLoadingCheck) {
            if (!isTpoOffer || isTpoOffer && isEligible) {
                refetchAuthorization();
            }
        }
    }, [
        isLoadingCheck
    ]);
    return {
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError,
        error: error
    };
};
