import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { CMS_CONFIG, getBackAndBlockinErrors, productSpecificError, useCmsConfig } from "@vfit/consumer/data-access";
import { API, getHome, PAGES, setTrackLink } from "@vfit/shared/data-access";
import { resetData, useTracking } from "@vfit/shared/providers";
import { useQueryClient } from "react-query";
import { getUserInfo, getTagging, getTrackError } from "../../../../../../checkout.utils";
import { ID_FLOWS } from "../../../../../../checkout.constants";
import { useCheckoutAutoWalletFlow } from "../../../../../../hooks/UseCheckoutAutoWalletFlow/useCheckoutAutoWalletFlow";
import { useCheckout } from "../../../../../../../iBuyMobile.context";
var AutoWalletSubmitCard = function() {
    var ref = useState(true), loadingForFinalPortIn = ref[0], setLoadingForFinalPortIn = ref[1];
    var ref1 = useCheckout(), checkoutErrors = ref1.checkoutErrors, product = ref1.product, portabilityStatus = ref1.portabilityStatus, setSlidesGoBack = ref1.setSlidesGoBack, setIsLastCard = ref1.setIsLastCard, setIsDisabledBackButton = ref1.setIsDisabledBackButton, setHideStickyBar = ref1.setHideStickyBar;
    var ref2 = useCheckoutAutoWalletFlow(), isLoading = ref2.isLoading, isSuccess = ref2.isSuccess, isError = ref2.isError, error = ref2.error;
    var orderErrors = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_INSTANT_ORDER_ERROR_MOBILE);
    var genericError = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GENERIC_ERROR_MOBILE);
    var client = useQueryClient();
    var buttonGenericError = productSpecificError(product).buttonGenericError;
    var taggingMobile = getTagging("checkout");
    var isDisabledRedirect = localStorage.getItem("disableRed");
    var ref3 = getBackAndBlockinErrors(orderErrors), goBackErrorOnPayment = ref3.goBackErrorOnPayment, blockingFormattedError = ref3.blockingFormattedError, blockingErrors = ref3.blockingErrors;
    var trackView = useTracking({
        event: [
            "order_loading"
        ],
        event_label: "verifying_payment_information",
        opts: taggingMobile === null || taggingMobile === void 0 ? void 0 : taggingMobile.opts,
        cartProduct: taggingMobile === null || taggingMobile === void 0 ? void 0 : taggingMobile.trackingProduct,
        pageProduct: taggingMobile === null || taggingMobile === void 0 ? void 0 : taggingMobile.pageProductsInfo,
        visitorTrackingOpts: getUserInfo()
    }).trackView;
    setTrackLink(trackView, "verifying_payment_information");
    var goToError = function() {
        var ref;
        var goBackAutoWallet = function() {
            resetData(client, [
                "paymentMethodPut",
                "getShoppingCartUpdatePayment"
            ]);
            setIsLastCard(false);
            setIsDisabledBackButton(false);
            setHideStickyBar(false);
            setSlidesGoBack(2);
        };
        var foundedErrors = goBackErrorOnPayment === null || goBackErrorOnPayment === void 0 ? void 0 : goBackErrorOnPayment.filter(function(el) {
            var ref, ref1;
            return ((ref = el.error) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === (error === null || error === void 0 ? void 0 : (ref1 = error.errorType) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase());
        });
        var foundedError = (error === null || error === void 0 ? void 0 : error.errorCode) ? foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors.find(function(el) {
            return el.errorCode == error.errorCode;
        }) : (foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors.find(function(el) {
            return !el.errorCode;
        })) || (foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors[0]);
        trackView(getTrackError(_object_spread_props(_object_spread({}, error), {
            isBlocking: (foundedError === null || foundedError === void 0 ? void 0 : foundedError.isBlocking) === "true"
        }), ID_FLOWS.UPFRONT_PAYMENT));
        checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref = checkoutErrors.showAndTrackError) === null || ref === void 0 ? void 0 : ref.call(checkoutErrors, genericError, goBackAutoWallet, error, foundedError === null || foundedError === void 0 ? void 0 : foundedError.title, foundedError === null || foundedError === void 0 ? void 0 : foundedError.message, goBackAutoWallet, "Riprova");
    };
    useEffect(function() {
        if (!isLoading && isSuccess && !isError) {
            if (!isDisabledRedirect) {
                window.open("".concat(getHome(), "/").concat(PAGES.THANK_YOU), "_self");
            }
        } else if (!isLoading && isError) {
            var ref;
            if (blockingFormattedError.includes(error === null || error === void 0 ? void 0 : (ref = error.errorType) === null || ref === void 0 ? void 0 : ref.toLowerCase())) {
                var ref1;
                var foundedErrors = blockingErrors.filter(function(el) {
                    var ref, ref1;
                    return ((ref = el.error) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === (error === null || error === void 0 ? void 0 : (ref1 = error.errorType) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase());
                });
                var foundedError = (error === null || error === void 0 ? void 0 : error.errorCode) ? foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors.find(function(el) {
                    return el.errorCode == error.errorCode;
                }) : (foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors.find(function(el) {
                    return !el.errorCode;
                })) || (foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors[0]);
                trackView(getTrackError(_object_spread_props(_object_spread({}, error), {
                    isBlocking: (foundedError === null || foundedError === void 0 ? void 0 : foundedError.isBlocking) === "true"
                }), ID_FLOWS.UPFRONT_PAYMENT));
                checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref1 = checkoutErrors.showAndTrackError) === null || ref1 === void 0 ? void 0 : ref1.call(checkoutErrors, genericError, function() {
                    if (!isDisabledRedirect) {
                        var ref;
                        window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.reload();
                    }
                }, error, foundedError === null || foundedError === void 0 ? void 0 : foundedError.title, foundedError === null || foundedError === void 0 ? void 0 : foundedError.message, function() {
                    if (!isDisabledRedirect) {
                        var ref;
                        window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.reload();
                    }
                }, buttonGenericError);
            } else {
                goToError();
            }
        }
    }, [
        isLoading,
        isError,
        isSuccess,
        loadingForFinalPortIn
    ]);
    useEffect(function() {
        if (portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isSuccess) {
            setLoadingForFinalPortIn(false);
        }
    }, [
        portabilityStatus
    ]);
    return null;
};
export default AutoWalletSubmitCard;
