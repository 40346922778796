import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { TPO_TKN } from "@vfit/consumer/data-access";
import { useEffect, useState } from "react";
import { errorManager, ErrorService } from "@vfit/shared/providers";
import { useThirdPartyEligibilityCustomer } from "@vfit/consumer/hooks";
import { useCheckout } from "../../iBuyMobile.context";
export var useTpoCheck = function() {
    var product = useCheckout().product;
    var ref = useState(true), isLoadingCheck = ref[0], setIsLoadingCheck = ref[1];
    var ref1 = useState({
        isStartFlow: false,
        customerToken: ""
    }), tpoEligibilityCheck = ref1[0], setTpoEligibilityCheck = ref1[1];
    var tpoToken = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem(TPO_TKN);
    var ref2 = useThirdPartyEligibilityCustomer(_object_spread({}, tpoEligibilityCheck)), isLoading = ref2.isLoading, isEligible = ref2.isEligible;
    var errorIsTpoNotEligible = function() {
        errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
            title: "Ops",
            message: "Si \xe8 verificato un problema. Riprovare in seguito",
            fullscreen: true,
            disableCloseModalOnClickAction: true
        });
    };
    useEffect(function() {
        if (!isLoading && !isEligible && tpoToken) {
            errorIsTpoNotEligible();
        } else if (isEligible && !isLoading) {
            setIsLoadingCheck(false);
        }
    }, [
        isLoading,
        isEligible
    ]);
    useEffect(function() {
        if ((product === null || product === void 0 ? void 0 : product.isTpoOffer) && !(product === null || product === void 0 ? void 0 : product.forceCampaign) && tpoToken) {
            setTpoEligibilityCheck({
                isStartFlow: true,
                customerToken: tpoToken
            });
        } else if ((product === null || product === void 0 ? void 0 : product.isTpoOffer) && !(product === null || product === void 0 ? void 0 : product.forceCampaign)) {
            errorIsTpoNotEligible();
        } else {
            setIsLoadingCheck(false);
        }
    }, [
        product
    ]);
    return {
        isTpoOffer: (product === null || product === void 0 ? void 0 : product.isTpoOffer) || false,
        isInCampaignTpo: (product === null || product === void 0 ? void 0 : product.forceCampaign) || false,
        isLoadingCheck: isLoadingCheck,
        isEligible: isEligible,
        token: tpoToken
    };
};
