export var CONTEXT_KEY = "ctxk";
export var DEFAULT_CUSTOMER_ID = "";
export var DEFAULT_DATA = {
    owningIndividual: {
        nation: "",
        fiscalCode: "",
        firstName: "",
        lastName: "",
        contactMedium: [
            {
                type: "email",
                preferred: true,
                emailAddress: ""
            },
            {
                type: "phone",
                preferred: false,
                phoneNumber: ""
            }, 
        ],
        identification: [
            {
                identificationType: "",
                nationality: "",
                identificationNumber: "",
                expirationDate: ""
            }, 
        ]
    },
    isValid: false,
    isLogged: false
};
export var DEFAULT_CONTACT_INFO = {
    checkContactProblem: true
};
export var DEFAULT_PORTABILITY_MIGRATION = {
    firstOperator: "",
    firstCode: "",
    firstCodeValid: false,
    secondOperator: "",
    secondCode: "",
    secondCodeValid: false,
    noCode: false
};
export var DEFAULT_PORTABILITY = {
    portabilityNumber: "",
    portabilityWant: undefined,
    portabilityMigration: {
        currentProvider: "",
        currentPlan: "",
        transferBalance: false
    }
};
export var DEFAULT_PORTABILITY_STATUS = {
    isStartPortIn: false,
    isStartFinalPortIn: false,
    isSuccessCheck: false,
    isLoadingCheck: false,
    isErrorCheck: false,
    isSuccess: false,
    isLoading: false,
    isError: false,
    errorMessage: ""
};
export var DEFAULT_CONSENTS = {
    partyPrivacyItems: []
};
export var DEFAULT_BILLING_ADDRESS = {
    city: "",
    postalCode: "",
    stateOrProvince: "",
    street: "",
    streetNumber: "",
    href: "",
    id: "",
    country: "",
    displayCountry: "",
    formattedAddress: "",
    formattedAddress1: "",
    addressStatus: "",
    externalId: "",
    homeZone: "",
    istatCode: ""
};
export var DEFAULT_SHIPPING_ADDRESS = {
    city: "",
    postalCode: "",
    stateOrProvince: "",
    street: "",
    streetNumber: "",
    href: "",
    id: "",
    country: "",
    displayCountry: "",
    formattedAddress: "",
    formattedAddress1: "",
    addressStatus: "",
    externalId: "",
    homeZone: "",
    istatCode: ""
};
export var DEFAULT_FIND_CALLER = {
    activeSimMobile: false,
    individualRef: {
        msisdn: "",
        email: ""
    },
    selectedContactMedium: {
        field: "",
        fieldType: ""
    },
    showOtp: false,
    verifyOtp: "",
    customerHasActiveSubs: false
};
export var DEFAULT_READ_ONLY = false;
export var DEFAULT_LAST_CARD = false;
export var DEFAULT_IS_FLOW_READY = false;
export var DEFAULT_IS_DELAYED_ICCID_RETRIVE = false;
export var DEFAULT_ICCID = null;
export var DEFAULT_SIM_OPTIONS = {
    cartId: "",
    simOptions: []
};
export var DEFAULT_TERMS_AND_CONDITIONS = {
    consensus: false,
    optionToReconsider: "",
    isSuccessTermsAndConditions: false,
    isLoadingTermsAndConditions: false,
    isErrorTermsAndConditions: false
};
export var DEFAULT_INVOICE = {
    option: ""
};
export var DEFAULT_UPFRONT_SELECTION = {
    option: ""
};
export var DEFAULT_RECHARGE_SELECTION = {
    option: "Yes"
};
export var DEFAULT_SLIDES_GO_BACK = -1;
export var DEFAULT_ADDRESS = {
    placeId: "",
    city: "",
    postalCode: "",
    stateOrProvince: "",
    street: "",
    streetNumber: ""
};
export var DEFAULT_DELIVERY_BASE = {
    isSuccess: false,
    isLoading: false,
    isError: false,
    errorMessage: "",
    deliveryOptions: [],
    identificationDetails: []
};
export var DEFAULT_DELIVERY_ADVANCE = {
    isSuccess: false,
    isLoading: false,
    isError: false,
    deliveryOptions: [],
    identificationDetails: []
};
export var DEFAULT_PICKUP_POINT = {
    storeId: "",
    locationAddress: {
        id: "",
        city: "",
        country: "",
        displayCountry: "",
        stateOrProvince: "",
        displayStateOrProvince: "",
        longitude: "",
        latitude: "",
        postalCode: "",
        formattedAddress1: "",
        postalOfficeID: "",
        postalOfficeDescription: ""
    },
    aerialDistance: 1.521
};
export var DEFAULT_IS_DISABLED_BACK_BUTTON = false;
export var DEFAULT_KEEPS_NUMBER = undefined;
export var DEFAULT_IS_VALID_NUMBER_PORTABILITY = false;
export var DEFAULT_WINBACK_NUMBER = "";
export var DEFAULT_OFFER_ID = "";
export var DEFAULT_UPDATE_PRODUCT_PRICE = {
    isUpdatePrice: false,
    isPriceUpdated: true
};
export var DEFAULT_PICKUP_LOCATION_STORE = {
    startDeliveryLocateAndPickupPoint: function() {},
    global: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    searchPickupLocations: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    searchPostalOffice: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    searchLocateStore: {
        isLoading: false,
        isSuccess: false,
        isError: false
    }
};
export var DEFAULT_BILLING_REPLACE = {
    validateAddress: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    createAddress: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    replaceAddress: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    billingPaper: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    billingEbill: {
        isLoading: false,
        isSuccess: false,
        isError: false
    }
};
export var DEFAULT_CART_ASYNC_INFO = {
    simTypeSelection: {
        isLoading: false,
        isSuccess: false,
        isError: false
    }
};
export var DEFAULT_CUSTOMER_DELIVERY_BASE_INFO = {
    deliveryBase: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        identificationDetails: [],
        deliveryOptions: [],
        errorMessage: ""
    },
    modifySimDevice: {
        isLoading: false,
        isSuccess: false,
        isError: false
    }
};
export var DEFAULT_CUSTOMER_ASYNC_INFO = {
    retrieveMultilines: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    termsAndConditions: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    customerInfo: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        data: undefined
    }
};
export var DEFAULT_AUTHORIZATION_ASYNC_INFO = {
    authorization: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    payMean: {
        isLoading: false,
        isSuccess: false,
        isError: false
    }
};
export var DEFAULT_CUSTOMER_FLOW = {
    findcaller: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    updateProductChatacteristics: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    patchCustomer: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    postCustomer: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    associateCustomer: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    customerInfo: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    payment: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        data: undefined
    },
    billingAccount: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    paymean: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        data: undefined
    },
    resetPatchAndPost: function() {},
    acceptedFallbackOffer: false
};
export var DEFAULT_CHECKOUT_ERRORS = {};
export var DEFAULT_VOUCHER_DISCOUNT = {
    voucherId: "",
    bo_array: [],
    isApplyVoucher: false,
    isAppliedVoucher: false,
    validateVoucher: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    voucher: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    shoppingCart: {
        isLoading: false,
        isSuccess: false,
        isError: false
    }
};
export var DEFAULT_CAMPAIGN = {
    campaign: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        isEligible: false,
        isAccepted: false,
        isRejected: false,
        campaignProduct: undefined
    },
    accept: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        isAccepted: false
    },
    updateCart: {
        isLoading: false,
        isSuccess: false,
        isError: false
    }
};
export var DEFAULT_SIM_TYPE_SELECTED_OPTION = undefined;
export var DEFAULT_IS_START_RECOGNITION = false;
export var DEFAULT_IS_START_ADDRESS = false;
export var DEFAULT_IS_START_DELIVERY_BASE = false;
export var DEFAULT_IS_NEW_ADDRESS = false;
export var DEFAULT_IS_START_AUTHORIZATION_ASYNC_INFO = false;
export var DEFAULT_IS_START_CAMPAIGN = false;
export var DEFAULT_IS_START_ACCEPT_CAMPAIGN = false;
export var DEFAULT_IS_START_CUSTOMER_FLOW = false;
export var DEFAULT_RECOGNITION_CODE = "";
export var DEFAULT_PRODUCT = {};
export var DEFAULT_BILLING_EMAIL = "";
