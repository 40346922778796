import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  padding-top: 16px;\n  padding-bottom: 16px;\n  flex-direction: row;\n  justify-content: center;\n  min-height: 72px;\n\n  span,\n  p {\n    color: ",
        ";\n  }\n\n  .header-top-banner-first-section {\n    display: flex;\n    align-items: center;\n    font-style: normal;\n    font-weight: 400;\n    font-family: ",
        ";\n    ",
        "\n    .after-logo-first-section {\n      margin-right: 32px;\n      font-family: ",
        ";\n      font-weight: 700;\n    }\n  }\n\n  .header-top-banner-second-section {\n    .after-logo-button-section {\n      margin-right: 12px;\n      font-family: ",
        ";\n      font-weight: 700;\n    }\n    display: flex;\n    align-items: center;\n    button {\n      ",
        "\n      border-color: ",
        ";\n      padding-top: 13px;\n      padding-bottom: 13px;\n    }\n    button:first-child {\n      font-family: ",
        ";\n      color: ",
        ";\n      text-transform: lowercase;\n      span::first-letter {\n        text-transform: uppercase;\n      }\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    height: 72px;\n    padding-top: 0;\n    padding-bottom: 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin-right: 12px;\n  height: 40px;\n  img {\n    object-fit: contain !important;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-34136076-0"
})(_templateObject(), function(param) {
    var color = param.color;
    return color || "black";
}, fonts.instrumentSansBold, pxToCssFont(16, 22), fonts.instrumentSansBold, fonts.instrumentSansBold, pxToCssFont(16, 22), function(param) {
    var color = param.color;
    return color || "black";
}, fonts.instrumentSansBold, function(param) {
    var color = param.color;
    return color || "black";
}, breakpoints.tablet);
export var LogoContainer = styled.div.withConfig({
    componentId: "sc-34136076-1"
})(_templateObject1());
