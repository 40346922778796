import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect } from "react";
import { errorMock, getCurrentUserType, getHubPath, ICampaignErrorType, IUserType, useCampaignAccept, useCampaignsInfoMobile, useGetShoppingCart } from "@vfit/consumer/data-access";
import { usePageProvider } from "@vfit/consumer/providers";
import { updateProductPrice } from "../../../ShoppingCart/shoppingCart.utils";
import { useCheckout } from "../../../iBuyMobile.context";
export var useForceCampaignFlow = function() {
    var ref, ref1, ref2;
    var ref3 = useCheckout(), product = ref3.product, campaign = ref3.campaign, isStartCampaign = ref3.isStartCampaign, isStartCampaignAccept = ref3.isStartCampaignAccept, setIsLoadingCart = ref3.setIsLoadingCart, setIsStartCampaign = ref3.setIsStartCampaign, setIsStartAcceptCampaign = ref3.setIsStartAcceptCampaign, setCampaign = ref3.setCampaign, setProduct = ref3.setProduct;
    var page = usePageProvider().page;
    var isCampaignProduct = (product === null || product === void 0 ? void 0 : product.isFamilyPlusProduct) || (product === null || product === void 0 ? void 0 : product.forceCampaign);
    // if product is familyplus from hub the familyplus id is the offerId else the familyPlusId in poduct configuration
    var campaignId = isCampaignProduct ? product === null || product === void 0 ? void 0 : product.offerId : product === null || product === void 0 ? void 0 : product.familyPlusId;
    var allProducts = (page === null || page === void 0 ? void 0 : (ref = page.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.productsAll) || [];
    var campaignProduct = allProducts === null || allProducts === void 0 ? void 0 : allProducts.find(function(el) {
        return el.offerId.toString() == campaignId;
    });
    var currentUserType = getCurrentUserType();
    var isProspectOrInactive = currentUserType === IUserType.PROSPECT_USER || currentUserType === IUserType.NEXT_USER_INACTIVE;
    var ref4 = useCampaignsInfoMobile({
        enabled: false
    }), campaignsData = ref4.data, isSuccessCampaigns = ref4.isSuccess, isErrorCampaigns = ref4.isError, isLoadingCampaigns = ref4.isLoading, refetchCampaignsInfoMobile = ref4.refetch;
    var ref5 = useCampaignAccept(), mutateAcceptCampaign = ref5.mutate, acceptCampaignData = ref5.data, isSuccessAcceptCampaign = ref5.isSuccess, isLoadingAcceptCampaign = ref5.isLoading, isErrorAcceptCampaign = ref5.isError;
    var ref6 = useGetShoppingCart((campaign === null || campaign === void 0 ? void 0 : (ref2 = campaign.accept) === null || ref2 === void 0 ? void 0 : ref2.acceptId) || "", {
        enabled: false
    }), isSuccessGetShoppingCart = ref6.isSuccess, isLoadingGetShoppingCart = ref6.isLoading, isErrorGetShoppingCart = ref6.isError, errorGetShoppingCart = ref6.error, getShoppingCartRefetch = ref6.refetch;
    // region UPDATE CART
    useEffect(function() {
        if (isLoadingGetShoppingCart && isSuccessAcceptCampaign) {
            if (isCampaignProduct) setIsLoadingCart(true);
            setCampaign(_object_spread_props(_object_spread({}, campaign), {
                updateCart: _object_spread_props(_object_spread({}, campaign.accept), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorGetShoppingCart && isSuccessAcceptCampaign) {
            var ref;
            if (isCampaignProduct) setIsLoadingCart(false);
            setCampaign(_object_spread_props(_object_spread({}, campaign), {
                updateCart: _object_spread_props(_object_spread({}, campaign.accept), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("shoppingCart", errorGetShoppingCart, undefined, undefined, "Error get shopping cart for family plus", false, {
                        familyPlusAcceptId: campaign === null || campaign === void 0 ? void 0 : (ref = campaign.accept) === null || ref === void 0 ? void 0 : ref.acceptId
                    }),
                    errorType: ICampaignErrorType.GET_SHOPPING_CART
                })
            }));
        } else if (isSuccessGetShoppingCart && isSuccessAcceptCampaign) {
            // if not is familyplus from hub
            // switch product
            if (!isCampaignProduct) {
                var hub = page ? getHubPath(page) : "";
                if (campaignProduct) {
                    localStorage.setItem("tProd_backUp", JSON.stringify(product));
                    setProduct(_object_spread_props(_object_spread({}, product, campaignProduct, hub && {
                        hubPath: hub
                    }, updateProductPrice(campaignProduct)), {
                        shoppingCartUrl: window.location.href
                    }));
                }
            } else {
                // else update family plus price
                setProduct(_object_spread({}, product, updateProductPrice(product)));
                setIsLoadingCart(false);
            }
            setCampaign(_object_spread_props(_object_spread({}, campaign), {
                updateCart: _object_spread_props(_object_spread({}, campaign.accept), {
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    errorType: undefined
                })
            }));
        }
    }, [
        isLoadingGetShoppingCart,
        isErrorGetShoppingCart,
        isSuccessGetShoppingCart
    ]);
    // endregion
    // region ACCEPT
    useEffect(function() {
        var ref;
        if ((campaign === null || campaign === void 0 ? void 0 : (ref = campaign.accept) === null || ref === void 0 ? void 0 : ref.acceptId) && !isSuccessGetShoppingCart && !isLoadingGetShoppingCart) getShoppingCartRefetch();
    }, [
        campaign
    ]);
    useEffect(function() {
        if (isLoadingAcceptCampaign) {
            setCampaign(_object_spread_props(_object_spread({}, campaign), {
                accept: _object_spread_props(_object_spread({}, campaign.accept), {
                    isLoading: true,
                    isSuccess: false,
                    isAccepted: false,
                    isError: false
                })
            }));
        } else if (isErrorAcceptCampaign) {
            setCampaign(_object_spread_props(_object_spread({}, campaign), {
                accept: _object_spread_props(_object_spread({}, campaign.accept), {
                    isLoading: false,
                    isSuccess: false,
                    isAccepted: false,
                    isError: true,
                    errorType: ICampaignErrorType.ACCEPT
                })
            }));
        } else if (isSuccessAcceptCampaign) {
            setCampaign(_object_spread_props(_object_spread({}, campaign), {
                accept: _object_spread_props(_object_spread({}, campaign.accept), {
                    isLoading: false,
                    isSuccess: true,
                    isAccepted: true,
                    isError: false,
                    acceptId: acceptCampaignData === null || acceptCampaignData === void 0 ? void 0 : acceptCampaignData.orderId
                }),
                updateCart: _object_spread_props(_object_spread({}, campaign.updateCart), {
                    isLoading: true
                })
            }));
        }
    }, [
        isLoadingAcceptCampaign,
        isErrorAcceptCampaign,
        isSuccessAcceptCampaign
    ]);
    useEffect(function() {
        var ref;
        if (isStartCampaignAccept && !(campaign === null || campaign === void 0 ? void 0 : (ref = campaign.accept) === null || ref === void 0 ? void 0 : ref.isAccepted)) {
            var ref1, ref2;
            setIsStartAcceptCampaign(false);
            mutateAcceptCampaign({
                campaignId: ((ref1 = campaign.campaign) === null || ref1 === void 0 ? void 0 : ref1.campaignId) || "",
                sessionId: ((ref2 = campaign.campaign) === null || ref2 === void 0 ? void 0 : ref2.sessionId) || ""
            });
        }
    }, [
        isStartCampaignAccept
    ]);
    // endregion
    // region CAMPAIGNS
    var checkHasCampaign = function() {
        var foundedCampaign = campaignsData === null || campaignsData === void 0 ? void 0 : campaignsData.find(function(item) {
            var ref, ref1;
            return (item === null || item === void 0 ? void 0 : (ref = item.productOfferId) === null || ref === void 0 ? void 0 : ref.toString()) === (campaignProduct === null || campaignProduct === void 0 ? void 0 : (ref1 = campaignProduct.offerId) === null || ref1 === void 0 ? void 0 : ref1.toString());
        });
        if ((product === null || product === void 0 ? void 0 : product.isFamilyPlusProduct) || (product === null || product === void 0 ? void 0 : product.forceCampaign)) {
            var ref, ref1;
            setCampaign(_object_spread_props(_object_spread({}, campaign), {
                campaign: _object_spread(_object_spread_props(_object_spread({}, campaign.campaign), {
                    isLoading: false,
                    isError: false,
                    isSuccess: true,
                    isEligible: !!foundedCampaign,
                    campaignId: (foundedCampaign === null || foundedCampaign === void 0 ? void 0 : (ref = foundedCampaign.id) === null || ref === void 0 ? void 0 : ref.toString()) || "",
                    sessionId: (foundedCampaign === null || foundedCampaign === void 0 ? void 0 : (ref1 = foundedCampaign.sessionId) === null || ref1 === void 0 ? void 0 : ref1.toString()) || ""
                }), !!foundedCampaign && {
                    campaignProduct: campaignProduct
                })
            }));
        }
    };
    useEffect(function() {
        if (isLoadingCampaigns) {
            setCampaign(_object_spread_props(_object_spread({}, campaign), {
                campaign: _object_spread_props(_object_spread({}, campaign.campaign), {
                    isSuccess: false,
                    isError: false,
                    isEligible: false,
                    isLoading: true
                })
            }));
        } else if (isErrorCampaigns) {
            setCampaign(_object_spread_props(_object_spread({}, campaign), {
                campaign: _object_spread_props(_object_spread({}, campaign.campaign), {
                    isLoading: false,
                    isSuccess: false,
                    isEligible: false,
                    isError: true,
                    errorType: ICampaignErrorType.CAMPAIGNS
                })
            }));
        } else if (isSuccessCampaigns) {
            checkHasCampaign();
        }
    }, [
        isLoadingCampaigns,
        isErrorCampaigns,
        isSuccessCampaigns
    ]);
    useEffect(function() {
        if (!isProspectOrInactive && isStartCampaign && !isSuccessCampaigns) {
            setIsStartCampaign(false);
            refetchCampaignsInfoMobile();
        }
    }, [
        isStartCampaign
    ]);
    // endregion
    return null;
};
