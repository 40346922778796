import { replaceShippingCost } from "@vfit/consumer/data-access";
export var organizeRecognitionSimCourier = function(data, isEsim, price, isPortability, isAutoWallet) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
    var recognitionCMS = isAutoWallet ? data === null || data === void 0 ? void 0 : (ref = data.personalinfomobile) === null || ref === void 0 ? void 0 : ref.recognitionCourierAutowallet : data === null || data === void 0 ? void 0 : (ref1 = data.personalinfomobile) === null || ref1 === void 0 ? void 0 : ref1.recognitionCourier;
    var recognitionEsimCMS = isAutoWallet ? data === null || data === void 0 ? void 0 : (ref2 = data.personalinfomobile) === null || ref2 === void 0 ? void 0 : ref2.recognitionCourierAutowallet : data === null || data === void 0 ? void 0 : (ref3 = data.personalinfomobile) === null || ref3 === void 0 ? void 0 : ref3.recognitionCourierEsim;
    var cmsConfig = isEsim ? recognitionEsimCMS : recognitionCMS;
    return {
        data: {
            title: replaceShippingCost((cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.title) || "", price),
            description: replaceShippingCost((cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.description) || "", price),
            textBold: replaceShippingCost(isPortability ? cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.textMnp : (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.textBold) || "", price),
            text: replaceShippingCost((cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.text) || "", price)
        },
        list: {
            title: replaceShippingCost((cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref4 = cmsConfig.list) === null || ref4 === void 0 ? void 0 : ref4[0].title) || "", price),
            items: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref5 = cmsConfig.list) === null || ref5 === void 0 ? void 0 : (ref6 = ref5[0]) === null || ref6 === void 0 ? void 0 : ref6.elements) || [
                {
                    text: "",
                    icon: ""
                }, 
            ],
            portabilityItems: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref7 = cmsConfig.list) === null || ref7 === void 0 ? void 0 : ref7[0].portability) || [
                {
                    text: "",
                    icon: ""
                }, 
            ]
        }
    };
};
