import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useTracking } from "@vfit/shared/providers";
import { SoftManagerService } from "@vfit/consumer/providers";
import { useQueryClient } from "react-query";
import { setTrackLink, setTrackView } from "@vfit/shared/data-access";
var TaggingHubApp = function(param) {
    var taggingOptions = param.taggingOptions, isDisabledInitialTrack = param.isDisabledInitialTrack, catalogElements = param.catalogElements;
    var client = useQueryClient();
    var allWgtIds = [];
    var allWgtNames = [];
    var allWgtCategory = [];
    if (catalogElements && (catalogElements === null || catalogElements === void 0 ? void 0 : catalogElements.length) > 0) {
        catalogElements === null || catalogElements === void 0 ? void 0 : catalogElements.forEach(function(catalogElement) {
            var ref;
            allWgtIds.push((catalogElement === null || catalogElement === void 0 ? void 0 : (ref = catalogElement.id) === null || ref === void 0 ? void 0 : ref.toString()) || "");
            allWgtNames.push((catalogElement === null || catalogElement === void 0 ? void 0 : catalogElement.title) || "");
            allWgtCategory.push((catalogElement === null || catalogElement === void 0 ? void 0 : catalogElement.category) || "");
        });
    }
    var trackView = useTracking({
        event: [
            (taggingOptions === null || taggingOptions === void 0 ? void 0 : taggingOptions.event) || "view"
        ],
        event_label: (taggingOptions === null || taggingOptions === void 0 ? void 0 : taggingOptions.eventLabel) || "",
        opts: _object_spread({}, taggingOptions),
        pageProduct: {
            product_id: allWgtIds,
            product_name: allWgtNames,
            product_category: allWgtCategory,
            product_price: "0",
            product_quantity: "0"
        },
        visitorTrackingOpts: SoftManagerService(client).getUser(),
        disableInitialTrack: isDisabledInitialTrack || false
    }).trackView;
    setTrackView(trackView);
    setTrackLink(trackView);
    return null;
};
export default TaggingHubApp;
