import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { checkIsGoBackSIA, CMS_CONFIG, ErrorCodes, errorMock, getCurrentUserType, IUserType, useAssociateCustomer, useBillingAccount, useCmsConfig, useCustomer, useGetCustomer, useGetFindCaller, useGetPaymean, usePatchCustomer, usePayment, useUpdateProductCharacteristic } from "@vfit/consumer/data-access";
import { useEffect } from "react";
import { API, computeInverse, getFromLocalStorageByKey, PAGES } from "@vfit/shared/data-access";
import { LoggerInstance, resetData } from "@vfit/shared/providers";
import { useQueryClient } from "react-query";
import { useCheckout } from "../../../iBuyFixed.context";
import { getBSNewMop, getErrorStatusText, getPatchPayload } from "./useCustomerFlow.utils";
import { ID_FLOWS } from "../../checkout.constants";
/**
 * Customer flow
 * if the user is NEXT ACTIVE call this flow
 * findcaller - getCustomer - associatecustomer - payment - billingaccount - paymean - updateproductcharacteristics
 * if the user in Prospect or Logged call
 * patchCustomer/postCustomer - getCustomer - associatecustomer - payment - billingaccount - paymean - updateproductcharacteristics
 * if user is logged
 * associatecustomer - payment - billingaccount - paymean - updateproductcharacteristics
 */ export var useCustomerFlow = function() {
    var ref, ref1;
    var queryClient = useQueryClient();
    var ref2 = useCheckout(), isStartCustomerFlow = ref2.isStartCustomerFlow, owningData = ref2.owningData, customerFlow = ref2.customerFlow, lockInFlow = ref2.lockInFlow, currentStepKey = ref2.currentStepKey, customerId = ref2.customerId, findCallerPreOTP = ref2.findCaller, setIsStartDeliveryBase = ref2.setIsStartDeliveryBase, setCustomerFlow = ref2.setCustomerFlow, setCustomerId = ref2.setCustomerId, setIsStartCustomerFlow = ref2.setIsStartCustomerFlow, setBillingEmail = ref2.setBillingEmail;
    var isNextActive = !!(owningData.isValid && findCallerPreOTP.verifyOtp);
    var isLogged = getCurrentUserType() === IUserType.LOGGED_USER;
    var globalConfig = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_CONFIG);
    var ref3 = usePayment({
        enabled: false
    }), paymentData = ref3.data, errorPayment = ref3.error, isSuccessPayment = ref3.isSuccess, isLoadingPayment = ref3.isLoading, isErrorPayment = ref3.isError, refetchPayment = ref3.refetch;
    var ref4 = useGetPaymean({
        enabled: false
    }, globalConfig), paymeanError = ref4.error, paymeanData = ref4.data, isSuccessPaymean = ref4.isSuccess, isLoadingPaymean = ref4.isLoading, isErrorPaymean = ref4.isError, refetchPaymean = ref4.refetch;
    var ref5 = useBillingAccount({
        enabled: false
    }, globalConfig), billingAccountError = ref5.error, isBillingAccountLoading = ref5.isLoading, isBillingAccountError = ref5.isError, isBillingAccountSuccess = ref5.isSuccess, billingAccountData = ref5.data, refetchBillingAccount = ref5.refetch;
    var ref6 = useAssociateCustomer(), associateCustomerData = ref6.data, associateCustomerError = ref6.error, associateCustomerMutate = ref6.mutate, isSuccessAssociateCustomer = ref6.isSuccess, isErrorAssociateCustomer = ref6.isError, isLoadingAssociateCustomer = ref6.isLoading;
    var ref7 = usePatchCustomer(), patchCustomerData = ref7.data, patchCustomerError = ref7.error, patchCustomerMutate = ref7.mutate, isSuccessPatchCustomer = ref7.isSuccess, isErrorPatchCustomer = ref7.isError, isLoadingPatchCustomer = ref7.isLoading;
    var ref8 = useCustomer(), postCustomerData = ref8.data, postCustomerError = ref8.error, postCustomerMutate = ref8.mutate, isSuccessPostCustomer = ref8.isSuccess, isErrorPostCustomer = ref8.isError, isLoadingPostCustomer = ref8.isLoading;
    var ref9 = useGetCustomer(customerId, {
        enabled: false
    }), customerData = ref9.data, customerError = ref9.error, isSuccessGetCustomer = ref9.isSuccess, isErrorGetCustomer = ref9.isError, isLoadingGetCustomer = ref9.isLoading, refetchGetCustomer = ref9.refetch;
    var ref10 = useUpdateProductCharacteristic(), mutateUpdateProductCharacteristics = ref10.mutate, updateProductCharacteristicsError = ref10.error, isErrorUpdateProductCharacteristics = ref10.isError, isSuccessUpdateProductCharacteristics = ref10.isSuccess, isLoadingUpdateProductCharacteristics = ref10.isLoading;
    var ref11 = useGetFindCaller({
        fiscalCode: owningData === null || owningData === void 0 ? void 0 : (ref = owningData.owningIndividual) === null || ref === void 0 ? void 0 : (ref1 = ref.fiscalCode) === null || ref1 === void 0 ? void 0 : ref1.toUpperCase()
    }, {
        enabled: false
    }), findcallerData = ref11.data, findcallerError = ref11.error, isErrorFindcaller = ref11.isError, isSuccessFindcaller = ref11.isSuccess, isLoadingFindcaller = ref11.isLoading, refetchFindcaller = ref11.refetch;
    var handleCRMErrorCodes = function(backendError) {
        switch(backendError){
            case "CRM-0020":
            case "CRM-0024":
                return ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT;
            case "CRM-0021":
            case "CRM-0023":
                return ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT;
            case "CRM-0022":
            case "CRM-0025":
                return ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT;
            default:
                return "";
        }
    };
    var isAlreadyDoneAssociateCustomer = function() {
        var alreadyDoneAssociateCustomer = getFromLocalStorageByKey("associateCustomer");
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        return !!shoppingCart && !!alreadyDoneAssociateCustomer;
    };
    useEffect(function() {
        if (isLoadingPaymean) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                paymean: _object_spread_props(_object_spread({}, customerFlow.paymean), {
                    error: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorPaymean) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                paymean: _object_spread_props(_object_spread({}, customerFlow.paymean), {
                    isSuccess: false,
                    isLoading: false,
                    isError: true,
                    error: errorMock("paymean", paymeanError)
                })
            }));
        } else if (isSuccessPaymean) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                paymean: _object_spread_props(_object_spread({}, customerFlow.paymean), {
                    error: undefined,
                    data: paymeanData,
                    isLoading: false,
                    isError: false,
                    isSuccess: true
                })
            }));
        }
    }, [
        isErrorPaymean,
        isLoadingPaymean,
        isSuccessPaymean
    ]);
    useEffect(function() {
        if (isBillingAccountLoading) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                billingAccount: _object_spread_props(_object_spread({}, customerFlow.billingAccount), {
                    error: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isBillingAccountError) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                billingAccount: _object_spread_props(_object_spread({}, customerFlow.billingAccount), {
                    isSuccess: false,
                    isError: true,
                    isLoading: false,
                    error: errorMock("billingAccount", billingAccountError)
                })
            }));
        } else if (isBillingAccountSuccess) {
            var isAlreadyDonePayMean = getFromLocalStorageByKey("payMean");
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                paymean: _object_spread({}, customerFlow.paymean, !isAlreadyDonePayMean && {
                    isLoading: true
                }),
                billingAccount: _object_spread_props(_object_spread({}, customerFlow.billingAccount), {
                    data: getBSNewMop(billingAccountData, globalConfig),
                    error: undefined,
                    isError: false,
                    isSuccess: true,
                    isLoading: false
                })
            }));
            setBillingEmail(billingAccountData.billDeliveryDetails.emailAddress);
            if (!isAlreadyDonePayMean) refetchPaymean();
        }
    }, [
        isBillingAccountError,
        isBillingAccountLoading,
        isBillingAccountSuccess,
        billingAccountData
    ]);
    useEffect(function() {
        if (isLoadingPayment) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                payment: _object_spread_props(_object_spread({}, customerFlow.payment), {
                    error: undefined,
                    isError: false,
                    isSuccess: true,
                    isLoading: false
                })
            }));
        } else if (isErrorPayment) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                payment: _object_spread_props(_object_spread({}, customerFlow.payment), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("payment", errorPayment)
                })
            }));
        } else if (isSuccessPayment) {
            var isAlreadyDoneBillingAccount = getFromLocalStorageByKey("billingAccount");
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                billingAccount: _object_spread({}, customerFlow.billingAccount, !isAlreadyDoneBillingAccount && {
                    isLoading: true
                }),
                payment: _object_spread_props(_object_spread({}, customerFlow.payment), {
                    data: paymentData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                })
            }));
            if (!checkIsGoBackSIA() && !isAlreadyDoneBillingAccount) refetchBillingAccount();
        }
    }, [
        isSuccessPayment,
        isErrorPayment,
        isLoadingPayment
    ]);
    useEffect(function() {
        if (isLoadingUpdateProductCharacteristics) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                updateProductChatacteristics: _object_spread_props(_object_spread({}, customerFlow.updateProductChatacteristics), {
                    error: undefined,
                    isError: false,
                    isSuccess: true,
                    isLoading: false
                })
            }));
        } else if (isErrorUpdateProductCharacteristics) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                updateProductChatacteristics: _object_spread_props(_object_spread({}, customerFlow.updateProductChatacteristics), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("updateProductChatacteristics", updateProductCharacteristicsError)
                })
            }));
        } else if (isSuccessUpdateProductCharacteristics) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                updateProductChatacteristics: _object_spread_props(_object_spread({}, customerFlow.updateProductChatacteristics), {
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                })
            }));
        }
    }, [
        isSuccessUpdateProductCharacteristics,
        isErrorUpdateProductCharacteristics,
        isLoadingUpdateProductCharacteristics, 
    ]);
    useEffect(function() {
        if (isLoadingAssociateCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                associateCustomer: _object_spread_props(_object_spread({}, customerFlow.associateCustomer), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorAssociateCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                associateCustomer: _object_spread_props(_object_spread({}, customerFlow.associateCustomer), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("associateCustomer", associateCustomerError)
                })
            }));
            resetData(queryClient, [
                "associateCustomer"
            ]);
        } else if (isSuccessAssociateCustomer) {
            var ref, ref1;
            if (((ref = associateCustomerData) === null || ref === void 0 ? void 0 : (ref1 = ref.cartValidation) === null || ref1 === void 0 ? void 0 : ref1.status) === "blocked") {
                setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                    resetPatchAndPost: resetPatchAndPost,
                    associateCustomer: _object_spread_props(_object_spread({}, customerFlow.associateCustomer), {
                        data: associateCustomerData,
                        isLoading: false,
                        isSuccess: false,
                        isError: true,
                        error: errorMock("associateCustomer", {
                            status: "500",
                            statusText: getErrorStatusText("500", ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED),
                            url: ""
                        })
                    })
                }));
            } else {
                var ref2, ref3, ref4;
                var isAlreadyDonePayment = getFromLocalStorageByKey("paymentData");
                setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                    resetPatchAndPost: resetPatchAndPost,
                    payment: _object_spread({}, customerFlow.payment, !isAlreadyDonePayment && {
                        isLoading: true
                    }),
                    associateCustomer: _object_spread_props(_object_spread({}, customerFlow.associateCustomer), {
                        data: associateCustomerData,
                        isLoading: false,
                        isSuccess: true,
                        isError: false,
                        error: undefined
                    })
                }));
                var customerLocal = getFromLocalStorageByKey("customerData");
                var findCallerInactive = getFromLocalStorageByKey("findCaller");
                if (!customerId && ((customerLocal === null || customerLocal === void 0 ? void 0 : (ref2 = customerLocal[0]) === null || ref2 === void 0 ? void 0 : ref2.id) || (findCallerInactive === null || findCallerInactive === void 0 ? void 0 : (ref3 = findCallerInactive.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id))) setCustomerId((customerLocal === null || customerLocal === void 0 ? void 0 : customerLocal[0].id) || (findCallerInactive === null || findCallerInactive === void 0 ? void 0 : (ref4 = findCallerInactive.customerRef) === null || ref4 === void 0 ? void 0 : ref4.id) || "");
                mutateUpdateProductCharacteristics(undefined);
                if (!isAlreadyDonePayment) refetchPayment();
                setIsStartDeliveryBase(true);
            }
        }
    }, [
        isSuccessAssociateCustomer,
        isErrorAssociateCustomer,
        isLoadingAssociateCustomer
    ]);
    useEffect(function() {
        if (isLoadingGetCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                customerInfo: _object_spread_props(_object_spread({}, customerFlow.customerInfo), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorGetCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                customerInfo: _object_spread_props(_object_spread({}, customerFlow.customerInfo), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("customerInfo", customerError)
                })
            }));
        } else if (isSuccessGetCustomer) {
            var isNextInactive = getCurrentUserType() === IUserType.NEXT_USER_INACTIVE;
            setCustomerFlow(_object_spread_props(_object_spread(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost
            }), !isNextInactive && !isAlreadyDoneAssociateCustomer() && {
                associateCustomer: {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    data: undefined,
                    error: undefined
                }
            }), {
                customerInfo: _object_spread_props(_object_spread({}, customerFlow.customerInfo), {
                    data: customerData === null || customerData === void 0 ? void 0 : customerData[0],
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                })
            }));
            if (!isAlreadyDoneAssociateCustomer() && !isLogged) {
                associateCustomerMutate();
            }
        }
    }, [
        isSuccessGetCustomer,
        isErrorGetCustomer,
        isLoadingGetCustomer
    ]);
    useEffect(function() {
        if (customerId) {
            var alreadyHaveCustomerData = getFromLocalStorageByKey("customerData");
            if (!alreadyHaveCustomerData) refetchGetCustomer();
        }
    }, [
        customerId
    ]);
    useEffect(function() {
        if (isLoadingPostCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                postCustomer: _object_spread_props(_object_spread({}, customerFlow.postCustomer), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorPostCustomer) {
            var ref, ref1, ref2;
            var headerErrorCode = ((ref = postCustomerError.headers) === null || ref === void 0 ? void 0 : ref.get("ERR-errorCode")) || "";
            var backendError = ((ref1 = postCustomerError.headers) === null || ref1 === void 0 ? void 0 : ref1.get("ERR-backendErrorCode")) || "";
            var backendMessage = ((ref2 = postCustomerError.headers) === null || ref2 === void 0 ? void 0 : ref2.get("ERR-backendErrorMessage")) || "";
            var errorCode = headerErrorCode === ErrorCodes.DIGITAL_CRM_CODE ? handleCRMErrorCodes(backendError) : headerErrorCode;
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                postCustomer: _object_spread_props(_object_spread({}, customerFlow.postCustomer), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("postCustomer", {
                        status: postCustomerError.status,
                        statusText: getErrorStatusText(postCustomerError.status, errorCode),
                        url: ""
                    }, backendError, errorCode, backendMessage)
                })
            }));
            resetData(queryClient, [
                "postCustomer"
            ]);
        } else if (isSuccessPostCustomer) {
            var alreadyHaveCustomerData = getFromLocalStorageByKey("customerData");
            setCustomerFlow(_object_spread_props(_object_spread(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost
            }), alreadyHaveCustomerData && {
                customerInfo: {
                    isError: false,
                    isSuccess: false,
                    isLoading: true,
                    data: undefined,
                    error: undefined
                }
            }), {
                postCustomer: _object_spread_props(_object_spread({}, customerFlow.postCustomer), {
                    data: postCustomerData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                })
            }));
            setCustomerId(postCustomerData.id);
        }
    }, [
        isSuccessPostCustomer,
        isErrorPostCustomer,
        isLoadingPostCustomer
    ]);
    useEffect(function() {
        if (isLoadingPatchCustomer) {
            var alreadyDoneAssociateCustomer = getFromLocalStorageByKey("associateCustomer");
            setCustomerFlow(_object_spread_props(_object_spread(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost
            }), alreadyDoneAssociateCustomer && {
                associateCustomer: _object_spread_props(_object_spread({}, customerFlow.associateCustomer, lockInFlow.associateCustomer), {
                    isSuccess: false
                })
            }), {
                patchCustomer: _object_spread_props(_object_spread({}, customerFlow.patchCustomer), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorPatchCustomer) {
            var ref, ref1, ref2;
            var headerErrorCode = (patchCustomerError === null || patchCustomerError === void 0 ? void 0 : (ref = patchCustomerError.headers) === null || ref === void 0 ? void 0 : ref.get("ERR-errorCode")) || "";
            var backendError = (patchCustomerError === null || patchCustomerError === void 0 ? void 0 : (ref1 = patchCustomerError.headers) === null || ref1 === void 0 ? void 0 : ref1.get("ERR-backendErrorCode")) || "";
            var backendMessage = ((ref2 = patchCustomerError.headers) === null || ref2 === void 0 ? void 0 : ref2.get("ERR-backendErrorMessage")) || "";
            var errorCode = headerErrorCode === ErrorCodes.DIGITAL_CRM_CODE ? handleCRMErrorCodes(backendError) : headerErrorCode;
            LoggerInstance.log("PatchCustomer", headerErrorCode, backendError, backendMessage, errorCode);
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                patchCustomer: _object_spread_props(_object_spread({}, customerFlow.patchCustomer), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("patchCustomer", {
                        status: patchCustomerError.status,
                        statusText: getErrorStatusText(patchCustomerError.status, errorCode),
                        url: ""
                    }, backendError, errorCode, backendMessage)
                })
            }));
            resetData(queryClient, [
                "patchCustomer"
            ]);
        } else if (isSuccessPatchCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost
            }), !isAlreadyDoneAssociateCustomer() && {
                associateCustomer: {
                    data: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                }
            }, isAlreadyDoneAssociateCustomer() && {
                associateCustomer: _object_spread_props(_object_spread({}, customerFlow.associateCustomer), {
                    isSuccess: true
                })
            }), {
                patchCustomer: _object_spread_props(_object_spread({}, customerFlow.patchCustomer), {
                    data: patchCustomerData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                }),
                customerInfo: _object_spread_props(_object_spread({}, customerFlow.customerInfo), {
                    data: patchCustomerData === null || patchCustomerData === void 0 ? void 0 : patchCustomerData[0],
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                })
            }));
            localStorage.setItem("customerData", JSON.stringify([
                patchCustomerData
            ]));
            if (!isAlreadyDoneAssociateCustomer()) {
                associateCustomerMutate();
            }
        }
    }, [
        isSuccessPatchCustomer,
        isErrorPatchCustomer,
        isLoadingPatchCustomer
    ]);
    useEffect(function() {
        if (isLoadingFindcaller) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                findcaller: _object_spread_props(_object_spread({}, customerFlow.findcaller), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorFindcaller) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                findcaller: _object_spread_props(_object_spread({}, customerFlow.findcaller), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("findcaller", findcallerError)
                })
            }));
        } else if (isSuccessFindcaller) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                customerInfo: {
                    isError: false,
                    isSuccess: false,
                    isLoading: true,
                    data: undefined,
                    error: undefined
                },
                findcaller: _object_spread_props(_object_spread({}, customerFlow.findcaller), {
                    data: findcallerData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                })
            }));
        }
    }, [
        isSuccessFindcaller,
        isErrorFindcaller,
        isLoadingFindcaller
    ]);
    var resetPatchAndPost = function(stateCustomerFlow) {
        var ref;
        if (stateCustomerFlow === null || stateCustomerFlow === void 0 ? void 0 : (ref = stateCustomerFlow.postCustomer) === null || ref === void 0 ? void 0 : ref.isError) {
            localStorage.removeItem("postCustomer");
            setCustomerFlow(_object_spread_props(_object_spread({}, stateCustomerFlow), {
                postCustomer: {
                    error: undefined,
                    data: undefined,
                    isLoading: false,
                    isError: false,
                    isSuccess: false
                }
            }));
        } else if (stateCustomerFlow === null || stateCustomerFlow === void 0 ? void 0 : stateCustomerFlow.patchCustomer.isError) {
            localStorage.removeItem("patchCustomer");
            setCustomerFlow(_object_spread_props(_object_spread({}, stateCustomerFlow), {
                patchCustomer: {
                    error: undefined,
                    data: undefined,
                    isLoading: false,
                    isError: false,
                    isSuccess: false
                }
            }));
        }
    };
    var createCustomer = function() {
        var cfData = computeInverse(owningData.owningIndividual.fiscalCode);
        postCustomerMutate({
            owningData: owningData,
            cfInverse: cfData
        });
    };
    useEffect(function() {
        if (isStartCustomerFlow) {
            setIsStartCustomerFlow(false);
        }
    }, [
        isStartCustomerFlow
    ]);
    var checkPrecondition = function() {
        if (isNextActive) {
            refetchFindcaller();
        } else if (isLogged) {
            if (!isAlreadyDoneAssociateCustomer()) {
                var ref, ref1;
                var customerLocal = (ref = getFromLocalStorageByKey("customerData")) === null || ref === void 0 ? void 0 : ref[0];
                setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                    resetPatchAndPost: resetPatchAndPost,
                    associateCustomer: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false,
                        data: undefined,
                        error: undefined
                    },
                    customerInfo: _object_spread_props(_object_spread({}, customerFlow.customerInfo), {
                        data: customerLocal,
                        isLoading: false,
                        isSuccess: true,
                        isError: false,
                        error: undefined
                    })
                }));
                if (!(customerFlow === null || customerFlow === void 0 ? void 0 : (ref1 = customerFlow.associateCustomer) === null || ref1 === void 0 ? void 0 : ref1.isLoading)) {
                    associateCustomerMutate();
                }
            }
        } else if (currentStepKey !== ID_FLOWS.CONTACT_CARD) {
            var isNextActiveInactive = getCurrentUserType() === IUserType.NEXT_USER_INACTIVE || getCurrentUserType() === IUserType.NEXT_USER_ACTIVE;
            var alreadyDonePostCustomer = getFromLocalStorageByKey("postCustomer");
            if (alreadyDonePostCustomer || isNextActiveInactive) {
                patchCustomerMutate(getPatchPayload(owningData));
            } else {
                createCustomer();
            }
        }
    };
    useEffect(function() {
        if (isStartCustomerFlow) checkPrecondition();
    }, [
        isStartCustomerFlow
    ]);
    useEffect(function() {
        setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
            resetPatchAndPost: resetPatchAndPost
        }));
    }, []);
    return null;
};
