import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-top: ",
        ";\n  padding: 40px 24px 32px;\n  display: flex;\n  flex-direction: column;\n  border: 1px solid ",
        ";\n  border-radius: ",
        ";\n  background-color: ",
        ";\n  // min-height: 333px;\n\n  .bottom-container-card-info {\n    position: relative;\n    justify-content: space-between;\n    display: flex;\n    flex-direction: column;\n  }\n\n  .wrapperIcon {\n    width: ",
        ";\n    height: ",
        ";\n  }\n\n  svg {\n    width: 100%;\n  }\n\n  .numberBox {\n    margin: 0;\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: ",
        ";\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  margin-top: 24px;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-top: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    margin-top: 24px;\n    ",
        "\n    color: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      margin: 0;\n      margin-top: 24px;\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  color: ",
        ";\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  .capitalize-first {\n    text-transform: lowercase;\n  }\n  .capitalize-first::first-letter {\n    text-transform: uppercase;\n  }\n  text-align: center;\n  margin-top: 20px;\n  width: 100%;\n  button,\n  a {\n    margin: 0 auto;\n    span {\n      overflow: hidden;\n      text-overflow: ellipsis;\n      width: 100%;\n      display: block;\n    }\n  }\n  white-space: nowrap;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-fd33ddbb-0"
})(_templateObject(), function(param) {
    var disableMarginTop = param.disableMarginTop;
    return "".concat(disableMarginTop ? "0" : "50px");
}, colors.$bebebe, function(param) {
    var isApp = param.isApp;
    return "".concat(isApp ? "6px" : "20px");
}, colors.$ffffff, function(props) {
    return "".concat(props.iconSize ? props.iconSize : 50, "px");
}, function(props) {
    return "".concat(props.iconSize ? props.iconSize : 50, "px");
}, fonts.light, pxToCssFont(42, 38), colors.$e60000, breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(70, 70));
export var Title = styled.p.withConfig({
    componentId: "sc-fd33ddbb-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(24, 30), breakpoints.desktop, pxToCssFont(36, 45), function(props) {
    return "".concat(props.type === "number" ? 16 : 24, "px");
});
export var TextContainer = styled.div.withConfig({
    componentId: "sc-fd33ddbb-2"
})(_templateObject2(), pxToCssFont(18, 22), colors.$262626, breakpoints.desktop, pxToCssFont(20, 30));
export var FrontLabel = styled.div.withConfig({
    componentId: "sc-fd33ddbb-3"
})(_templateObject3(), fonts.regular, pxToCssFont(18, 24), colors.$262626);
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-fd33ddbb-4"
})(_templateObject4());
